import {NgModule, ErrorHandler, DEFAULT_CURRENCY_CODE} from '@angular/core';
import {LOCALE_ID} from '@angular/core';
import {Location, registerLocaleData} from '@angular/common';
import localeForPrices from '@angular/common/locales/de-CH';
import localeForDates from '@angular/common/locales/de-CH';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as Sentry from "@sentry/angular-ivy";

const localeNameForPrices = "de-CH";
const localeNameForDate = "fr-CH";
registerLocaleData(localeForPrices, localeNameForPrices);

import {Router, RouterModule} from '@angular/router';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {rootRouterConfig} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {AppComponent} from './app.component';

import {HttpClientModule} from '@angular/common/http';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};
import {AuthGuard} from './shared/guards/auth.guard';
import {ApiService} from './shared/services/api.service';
import {environment} from '../environments/environment';
import {ProtoHebdoComponent} from './views/proto-hebdo/proto-hebdo.component';
import {SharedMaterialModule} from './shared/shared-material.module';
import {FlexModule} from '@angular/flex-layout';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateAdapter, MAT_DATE_LOCALE, MatDateFormats} from "@angular/material/core";
import {PrintProtoPageComponent} from './views/proto-hebdo/print-proto-page/print-proto-page.component';
import {ActionsComponent} from './views/actions/actions.component';
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {DocumentsComponent} from './views/documents/documents.component';
import {SiteHomeComponent} from './views/site-home/site-home.component';
import {OverlayModule} from "@angular/cdk/overlay";
import {AvatarModule} from "ngx-avatar";
import {ChaptersCpnComponent, TreeItem} from './views/subs/chapters-cpn/chapters-cpn.component';
import {PageTableComponent} from "./views/proto-hebdo/PageTableCpnt";
import {SuiviComponent} from './views/reports/suivi/suivi.component';
import {MensuelComponent} from './views/reports/mensuel/mensuel.component';
import {HighchartsChartModule} from "highcharts-angular";
import {SiteSelectComponent} from "./views/site-select/site-select.component";
import {SiteEditorComponent} from "./views/site-home/SiteEditorCpnt";
import {GlobalDataComponent} from './views/admin/global-data/global-data.component';
import {MomentModule} from "ngx-moment";
import {IconFluidComponent} from "./shared/components/IconFluid";
import {StatmensConfigComponent} from './views/admin/statmens-config/statmens-config.component';
import {StatmensPlotComponent} from "./views/subs/StatmensPlotCpnt";
import {StatmensCassureComponent} from "./views/subs/StatmensCassureCpnt";
import {RowItemComponent} from "./views/reports/mensuel/RowItem";
import {PdmEditorComponent} from './views/admin/pdm-editor/pdm-editor.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {ReleveRowComponent} from "./views/admin/pdm-editor/releve-row/releve-row-component";
import {XlsMapperComponent} from "./views/admin/pdm-editor/children/xls-mapper.component";
import {SnifferComponent} from "./views/admin/pdm-editor/children/sniffer.component";
import {ToastrModule} from "ngx-toastr";
import {StatmensPreviewComponent} from "./views/subs/StatmensPreviewCpnt";
import {StatmensTableComponent} from "./views/subs/StatMensTable";

import {FormRegComponent} from './views/admin/pdm-editor/form-reg/form-reg.component';
import {FormPdmComponent} from "./views/admin/pdm-editor/form-pdm/form-pdm.component";

import {SaisieComponent} from './views/admin/saisie/saisie.component';
import {SaisieRowComponent} from "./views/admin/saisie/saisie-row-component";
import {NgxFileDropModule} from "ngx-file-drop";
import {PdmYearlyConfigComponent} from "./views/admin/pdm-editor/children/pdm-yearly-config.component";
import {SuiviEnergyEfficiencyComponent} from './views/reports/suivi/suivi-energy-efficiency.component';
import {SuiviInfluenceFactorsComponent} from './views/reports/suivi/suivi-influence-factors.component';
import {PdmImportComponent} from "./views/admin/pdm-editor/children/pdm-import.component";
import {SuiviEnergyComponent} from "./views/reports/suivi/suivi-energy.component";
import {SuiviSubMetersComponent} from "./views/reports/suivi/suivi-submeters.component";
import {StrEnhDirective} from "./shared/directives/str-enh.directive";
import {SuiviDocumentsComponent} from "./views/reports/suivi/suivi-documents.component";
import {MarkDownComponent} from "./views/subs/mark-down.component";
import {SuiviPageDeGardeComponent} from "./views/reports/suivi/suivi-page-garde.component";
import {SuiviGoalsComponent} from "./views/reports/suivi/suivi-goals.component";
import {ActionTableRow, SuiviActionsComponent} from "./views/reports/suivi/suivi-actions.component";
import {MarkdownModule, MarkdownService} from "ngx-markdown";
import {BlocConsComponent} from "./views/site-home/BlocCons";
import {BlocActionsSummaryComponent} from "./views/site-home/BlocActionsSummary";
import {BmensMetersComponent} from "./views/site-home/bmens-meters.component";
import {BmensComponent} from './views/reports/bmens/bmens.component';
import {ReportCoverComponent} from "./views/proto-hebdo/Report-cover.component";
import {BsInmailerComponent} from "./views/subs/bs-inmailer/bs-inmailer.component";
import {ActionRowComponent} from './views/actions/action-row.component';
import {ActionPrintComponent} from "./views/actions/actions-print.component";
// import {AngularFireAuthGuardModule} from "@angular/fire/compat/auth-guard";
// import {AngularFireAuthModule} from "@angular/fire/compat/auth";
// import {AngularFireStorageModule} from "@angular/fire/compat/storage";
// import {AngularFireModule} from "@angular/fire/compat";
import {FileUploadModule} from "ng2-file-upload";
import {StorageUploaderComponent} from "./views/subs/storage-uploader";
import {BlocEnergyStatsComponent} from "./views/site-home/BlocEnergyStats";
import {SuiviGeneralInfosComponent} from "./views/reports/suivi/suivi-general-infos.component";
import {UsersComponent} from './views/admin/manager/users/users.component';
import {AnnotEditorComponent} from "./views/proto-hebdo/Annot-editor.component";
import {BaseComponent} from "./shared/BaseComponent";
import {BsActionComponent} from "./views/subs/bs-action/bs-action.component";
import {ActionEconItemComponent} from "./views/actions/action-econ-item.component";
import {CurrentSessionsComponent} from './views/admin/manager/current-sessions.component';
import {ClientsComponent} from './views/admin/manager/clients/clients.component';
import {NgxMaskModule} from "ngx-mask";
import {PlotlyViaCDNModule} from "angular-plotly.js";
import {BlocUsageForYearComponent} from "./views/site-home/BlocUsageForYear";
import {SETTINGS} from "@angular/fire/compat/firestore";
import {SuiviEnergyEvolComponent} from "./views/reports/suivi/suivi-energy-evol.component";
import {BmensCommentComponent} from "./views/reports/bmens/bmens-comment";
import {PlotComComponent} from "./views/proto-hebdo/plot-com.component";
import {MonitorReportingComponent} from "./views/admin/manager/monitor-reporting.component";
import {LuxonModule} from "luxon-angular";
import {MonitorEconComponent} from "./views/admin/manager/monitor-econ.component";
import {MonitorBmensComponent} from "./views/admin/manager/monitor-bmens.component";
import {StatmensBarsComponent} from "./views/subs/StatmensBarsCpnt";
import {StatmensPrevisionsComponent} from "./views/subs/StatmensPrevisions";


PlotlyViaCDNModule.setPlotlyVersion('2.31.0'); // can be `latest` or any version number (i.e.: '1.40.0')
PlotlyViaCDNModule.setPlotlyBundle('basic');

// import {initializeApp} from "firebase/app";
// import {provideFirebaseApp, getApp} from '@angular/fire/app';

import {provideFirebaseApp, getApp, initializeApp} from '@angular/fire/app';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getDatabase, provideDatabase} from '@angular/fire/database';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {StatmensCumulComponent} from "./views/subs/StatmensCumul";
// import {} from '@angular/fire/auth-guard';

// const app = initializeApp(environment.firebase);
Sentry.init({
    dsn: "https://c848831dc33c8cfc35f1af5da05581ba@o257828.ingest.sentry.io/4506050681438208",
});

export const MY_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'MM-DD-YYYY',
    },
    display: {
        dateInput: 'MM-DD-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, BaseComponent,
        ProtoHebdoComponent, PlotComComponent, PrintProtoPageComponent,
        SiteSelectComponent, AnnotEditorComponent,
        ReportCoverComponent, BsInmailerComponent,
        ActionsComponent, ActionPrintComponent, DocumentsComponent, SiteHomeComponent,
        BsActionComponent, ActionEconItemComponent, ActionTableRow,
        ChaptersCpnComponent, TreeItem, PageTableComponent, SuiviComponent,
        // ------
        MensuelComponent, SiteEditorComponent, GlobalDataComponent,
        RowItemComponent, PdmYearlyConfigComponent, PdmEditorComponent, ReleveRowComponent, SaisieRowComponent,
        SnifferComponent,
        StatmensPlotComponent, StatmensCassureComponent, StatmensPreviewComponent, StatmensTableComponent,
        StatmensPrevisionsComponent, StatmensCumulComponent,
        IconFluidComponent, StatmensConfigComponent, XlsMapperComponent, PdmEditorComponent,
        PdmImportComponent, BmensMetersComponent,

        // suivi + bilan mens old
        SuiviSubMetersComponent, SuiviDocumentsComponent, MarkDownComponent,
        SuiviPageDeGardeComponent, BlocConsComponent, BlocActionsSummaryComponent,
        BlocEnergyStatsComponent, BlocUsageForYearComponent,
        FormPdmComponent, SaisieComponent,
        SuiviEnergyEvolComponent, SuiviEnergyEfficiencyComponent, SuiviInfluenceFactorsComponent,
        SuiviEnergyComponent, SuiviGoalsComponent, SuiviActionsComponent,
        StorageUploaderComponent,
        FormRegComponent,

        // *****directives
        StrEnhDirective,
        BmensComponent, BmensCommentComponent,

        BsInmailerComponent, StatmensBarsComponent,
        ActionRowComponent,
        SuiviGeneralInfosComponent,
        UsersComponent,
        CurrentSessionsComponent, MonitorReportingComponent, MonitorEconComponent, MonitorBmensComponent,
        ClientsComponent
    ],
    imports: [
        CommonModule,
        PlotlyViaCDNModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        NgxFileDropModule,
        SharedMaterialModule,
        FormsModule,
        HttpClientModule,
        FileUploadModule,
        ReactiveFormsModule,
        OverlayModule,
        AvatarModule,
        NgxSpinnerModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                'm': 59
            }
        }),
        LuxonModule,
        //     NgxIndexedDBModule.forRoot(dbConfig),
        NgxExtendedPdfViewerModule,
        //
        //
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
        provideDatabase(() => getDatabase()),
        provideAuth(() => getAuth()),
        // AngularFireModule.initializeApp(environment.firebase),
        // AngularFireStorageModule, // imports firebase/firestore, only needed for database features
        // AngularFirestoreModule.enablePersistence(), // imports firebase/firestore, only needed for database features
        // AngularFireAuthModule, // imports firebase/auth, only needed for auth features
        // AngularFireAuthGuardModule, // imports firebase/auth, only needed for auth features
        //
        NgxExtendedPdfViewerModule,
        PerfectScrollbarModule,
        HighchartsChartModule,
        MarkdownModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 500,
            positionClass: 'toast-bottom-left',
            // preventDuplicates: true,
        }),
        DragDropModule,
        RouterModule.forRoot(rootRouterConfig, {useHash: false}),
        FlexModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        AuthGuard,
        ApiService,
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'Fr. '},
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        // {
        //     provide: SETTINGS,
        //     useValue: {experimentalAutoDetectLongPolling: true, merge: true},
        // },
        // {provide: ErrorHandler, useClass: ErrorHandlerService},
        // { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        {provide: FIREBASE_OPTIONS, useValue: environment.firebase},
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        {provide: LOCALE_ID, useValue: localeNameForPrices},// currency pipe
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        // {provide: MAT_DATE_LOCALE, useValue: MY_FORMATS}
        {provide: MAT_DATE_LOCALE, useValue: localeNameForDate}//datepicker

    ]
})
export class AppModule {
}
