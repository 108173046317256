import {
    AfterViewInit,
    Component,
    ElementRef, HostListener,
    Injector,
    NgZone,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';

import {H} from "../../shared/helpers/H";
import {concat, from, Observable, Subscription} from "rxjs";
import {concatMap, take} from "rxjs/operators";
import {DomainReportPage, ObsWrapper, ReportAnnotation} from "../../shared/models/models";
import {PlotlyComponent, PlotlyService} from "angular-plotly.js";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {BaseComponent} from "../../shared/BaseComponent";
import {MatSidenav} from "@angular/material/sidenav";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {AppService} from "../../shared/services/app.service";
import {ApiService} from "../../shared/services/api.service";
import {AnnotationService} from "./annot.service";
import {SiteService} from "../../shared/services/site.service";
import {LayoutService} from "../../shared/services/core/layout.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Optimise} from "../../shared/helpers/Optimise";
import {egretAnimations} from "../../shared/animations/egret-animations";
import {ActivatedRoute, ActivatedRouteSnapshot} from "@angular/router";
import {ProtoReport, ProtoReportPage, ProtoReportRawPage} from "../../shared/models/ProtoHebdo";
import {SiteEvent} from "../../shared/models/Notifs";
import {MatExpansionPanel} from "@angular/material/expansion";


@Component({
    selector: 'app-proto-hebdo',
    templateUrl: './proto-hebdo.component.html',
    styleUrls: ['./proto-hebdo.component.scss'],
    animations: egretAnimations
})
export class ProtoHebdoComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild("autosize", {static: true}) autosize: CdkTextareaAutosize;
    @ViewChild('plotHolder') plotHolder: ElementRef<HTMLElement>;
    @ViewChildren('plot') plots: QueryList<PlotlyComponent>;

    @ViewChild('sidenav1') sidenav1: MatSidenav;
    @ViewChild('sidenav2') sidenav2: MatSidenav;
    @ViewChild('plotBloc', {static: false}) plotBloc: ElementRef;
    @ViewChild('pdfBloc', {static: false}) pdfBloc: ElementRef;

    public overlayRef: OverlayRef | null;

    public events: SiteEvent[];
    public pages: DomainReportPage[];

    public rawPages: ProtoReportRawPage[] = [];
    public reportPages: ProtoReportPage[] = [];

    public pagesGenDurationMap = {};
    public pagesGraphsMap = {};
    public pagesGraphsArray = [];
    public pageRawJson: any;
    public graphsTitlesMap: Map<string, string> = new Map<string, string>();//map visual_key to title
    public graphsAdapted = [];

    public currentPageIndex = 0;
    public currentPage: ProtoReportPage;
    public cellsWidth = [];
    public pagesToPrint: Map<number, any> = new Map<number, any>();
    public levelsPages = {};
    public clientHeight = 0;
    public clientWidth = 0;
    public pageLoaded = 0;
    public pageCount: number;
    public graphs = [];
    public graphsLayoutForPrint = [];
    public graph = {data: null, layout: null, origin: null, shape: null};
    public defaultConfig = {
        // hide plotly logo
        displayLogo: false,
        // disable native (plotly.js) toolbar
        displayModeBar: false,
        editable: false,
        responsive: false,
        edits: {
            annotationPosition: true,
            annotationTail: true,
            annotationText: false,
            shapePosition: false,
            axisTitleText: false,
            colorbarPosition: false,
            colorbarTitleText: false,
            legendPosition: false,
            legendText: false,
            titleText: false,
        },
        locale: 'fr-CH',
    };
    public printConfig = {};

    public commentReplyMode = false;
    public compareTargetDate: string = null;
    public regenUrl: string;
    public pleaseWait = false;
    public pagesTree = [];
    public sideNavMode = '';
    public fsMode = true;
    public menuLeftType = 'chapters';
    public pdfSource = '';
    public annotWithNotif = [];
    public pagesToPrintPngMap: Map<string, string> = new Map<string, string>();

    // public urlRef: string;
    public urlWeek: string;
    public urlYear: string;
    public siteLoadingStatusCache: number = 0;

    public routeSubscription: Subscription = null;
    public firebaseEventSubscription: Subscription = null;
    public appInitSubscription: Subscription = null;
    public showList = false;

    constructor(public myapp: AppService, public api: ApiService,
                public annotServ: AnnotationService,
                public overlay: Overlay, public site: SiteService,
                public viewContainerRef: ViewContainerRef,
                public plotlyServ: PlotlyService,
                private currentRoute: ActivatedRoute,
                public layout: LayoutService, public sanitizer: DomSanitizer) {
        super();
        this.printConfig = {...this.defaultConfig, staticPlot: true, showlegend: false};
    }

    get isTable(): boolean {
        let retVal = false;
        if (this.pageRawJson) {
            // console.log("IS TABLEEEE", this.pageRawJson);
            const visualKeys = Object.keys(this.pageRawJson);
            if (visualKeys && visualKeys.length >= 1) {
                const firstVisual = this.pageRawJson[visualKeys[0]];
                if (firstVisual && firstVisual.meta && firstVisual.meta.type === 'TABLE')
                    retVal = true;
            }
        }
        return retVal;
    }

    get sideBarTitle() {
        let title = '';
        if (this.sideNavMode === 'print') title = 'Gestion de l\'impression';
        if (this.sideNavMode === 'comment') title = 'Annotations';
        if (this.sideNavMode === 'openAnnotMap') {
            if (this.annotServ.isCommentCreatingMode)
                title = 'Créer commentaire et annotations';
            else
                title = 'Consulter le commentaire';
        }
        this.annotServ.sideNavMode = this.sideNavMode;
        /*
         <div *ngIf="sideNavMode==='comment'">Annotations</div>
                    <div *ngIf="sideNavMode==='openAnnotMap'">
                        <ng-container *ngIf="annotServ.newAnnotKeys.length">
                            <span *ngIf="annotServ.isCommentCreatingMode">Créer commentaire et annotations</span>
                            <span *ngIf="!annotServ.isCommentCreatingMode">Consulter le commentaire</span>
                        </ng-container>
                        <span *ngIf="!annotServ.newAnnotKeys.length">Détails du point cliqué</span>
                    </div>
                    <div *ngIf="sideNavMode==='editAnnotation'">Editer cette annotation</div>
                    <div *ngIf="sideNavMode==='print'">Gestion de l'impression</div>
         */
        return title;
    }

    get canShare() {
        return this.myapp.isAdminOrSuperUser || (this.myapp.userRightsForRoute
            && this.myapp.userRightsForRoute.includes('SHARE'));
    }

    get canCommentReply() {
        return this.myapp.isAdminOrSuperUser || (this.myapp.userRightsForRoute
            && this.myapp.userRightsForRoute.includes('COMMENT_REPLY'));
    }

    get annotsWithNotifs() {
        if (this.myapp.notifs) {
            const r = this.site.notifsOfCurrentsite.filter(it => it.route === 'PROTOHEBDO')
                .map(it => {
                    return it.targetUID;
                })
            return r;
        }
        return [];
    }

    ngOnInit() {
        this.layout.layoutConf$.subscribe(conf => {
            //console.log("Layout change", conf);
            setTimeout(() => {
                this.detectClientDimensions();
                this.resetFullScreen();
            }, 500);
        });

        for (let i = 0; i < 8; i++)
            this.graphsAdapted.push(this.graph);

        this.routeSubscription = this.currentRoute.params.subscribe(queryParams => {
            // console.log("Site::------------------------------>>>>initSubscriptions", 'routeSubscription 11', queryParams);
            const urlUID = queryParams['site'];
            this.urlWeek = queryParams['week'];
            this.urlYear = queryParams['year'];

            if (this.siteLoadingStatusCache === SiteService.MAX_SITE_LOADING_STATUS) {
                const oldSiteUID = this.site.clientSite.uid;
                if (urlUID && urlUID !== oldSiteUID) {
                    console.error("ERROR SITE changed 1111");
                    this.site.loadReportsAndDetectNew();
                } else {
                    // select report when week and year change
                    this.selectReport(true);
                }
            }
        });
        //console.log("initSubscriptions", 'routeSubscription 22', this.routeSubscription);

        if (this.myapp.user)
            this.myapp.storeCurrentRoute();
        else
            this.myapp.appInitStatus.subscribe(status => {
                if (status === 1)
                    this.myapp.storeCurrentRoute();
            });
        this.site.siteLoadingStatus.subscribe(status => {
            this.siteLoadingStatusCache = status;
            if (status === SiteService.MAX_SITE_LOADING_STATUS) {
                this.events = [];
                // get user protohebdo rights
                this.myapp.userRightsForRoute = this.myapp.user.getRightsForSiteAndRoute('PROTOHEBDO');
                this.bootstrap('ProtoHebdo::bootstrap---> siteLoadingStatus= ' + status);

            }
        });
    }

    togglePlotsViewMode() {
        if (this.showList) {
            this.currentPage = null;
        }
        console.log("togglePlotsViewMode:NEW Event", this.showList);
    }

    initSubscriptions() {
        if (!this.routeSubscription) {
        }
        if (!this.firebaseEventSubscription)
            this.firebaseEventSubscription = this.myapp.firebaseNewEventsDetector.subscribe(event => {
                console.log("firebaseEventDetector:NEW Event", event);
                // this.loadReportsAndDetectNew();
            })
    }

    bootstrap(source = 'none') {
        // console.log("Site::-------------ProtoHebdoComponent::bootstrap:", source, this.site.clientSite);
        this.initSubscriptions();
        if (!this.events || this.events.length === 0) {
            this.api.getProtoEvents(this.site.clientSite.uid, 'share-proto-hebdo')
                .subscribe(resp => {
                    if (resp) {
                        this.events = resp.body.map(it => {
                            const ev = new SiteEvent(it);
                            if (this.site.selectedSiteReport && ev.related_data
                                && ev.related_data.reportKey === this.site.selectedSiteReport.report_key) {
                            }
                            return ev;
                        });
                    }
                    console.log("this.events :", this.events);
                });
        }
        this.selectReport(true);
    }

    // select last report, or the report from url or reselect the already selected
    selectReport(andLoadPages = false) {
        this.reportPages = [];
        this.currentPage = null;
        // console.log("Site::-------------selectReport", 'andLoadPages ??', andLoadPages);
        if (this.urlYear && this.urlWeek) {
            // console.log("Site::-------------loadReportAndLoadItsPages::FOR WEEK and YEAR", this.urlYear, this.urlWeek);
            this.site.selectedSiteReport = this.site.selectedSiteReports.find(it =>
                it.year === Number(this.urlYear) && it.week === Number(this.urlWeek));
            if (!this.site.selectedSiteReport) {
                this.site.selectedSiteReport = this.site.selectedSiteReportsToValidate.find(it =>
                    it.year === Number(this.urlYear) &&
                    it.week === Number(this.urlWeek));
            }
        }
        if (!this.site.selectedSiteReport) {
            //
            const latestReport = this.site.selectedSiteReports[0];
            if (latestReport) {
                // a bug in angularRouter make a navgiation fail when too close in time. Thus settimeOut()
                // https://github.com/angular/angular/issues/34051
                setTimeout(() => {
                    this.site.router.navigate(
                        ['/proto-hebdo/' + latestReport.uid
                        + "/" + latestReport.year
                        + '/' + latestReport.week]);
                }, 200);
                // this.site.selectedSiteReport = latestReport;

                // console.log("Site::-------------selectReport", 'Select latest ??', latestReport);
            } else {
                // this.site.loadReportsAndDetectNew();
                /*
                this.myapp.confirm.confirm({title: 'Pas de rapports', message: "Voulez vous recharger les rappors"})
                    .subscribe(isoK => {
                        if (isoK) this.site.loadReportsAndDetectNew();
                    })*/
            }
            console.log("Site::-------------selectReport",
                this.site.siteLoadingStatusInt,
                this.site.selectedSiteReport, 'latestReport', latestReport);
            if (this.site.selectedSiteReport)
                this.initShareEvent();
        } else {
            if (andLoadPages) this.loadSelectedReportData();
        }

    }

    loadSelectedReportData() {
        // console.log("Site::-------------loadReportAndLoadItsPages ", this.site.selectedSiteReport);
        // load pages and select report
        this.api.getReportData(this.site.clientSite.ref, this.site.selectedSiteReport.date_str)
            .subscribe(resp => {
                this.rawPages = [];
                if (resp && resp.status === 1) {
                    resp.pages.forEach(p => {
                        this.rawPages.push(new ProtoReportRawPage(p));
                    });
                    this.site.selectedSiteReport = new ProtoReport(resp.report, resp.jarInfos, resp.pyInfos);
                    this.reportPages = this.rawPages.map(it => it.getPage(this.site.selectedSiteReport.date_str))
                        .sort((p1, p2) => {
                            return p1.position - p2.position;
                        });

                    // if (resp.jarInfos) this.myapp.showMessage("Jar disponible");
                    // console.log("loadReportAndLoadItsPages::this.api.getReportData:", 'Resp', this.site.selectedSiteReport);
                    // console.log("loadReportAndLoadItsPages", this.reportPages);
                    this.renderReportAndLoadComments(this.site.selectedSiteReport);
                } else {
                    this.myapp.showError("Rapport vide");
                    console.error("Erreur getReportData: resp null");
                }

            }, error => {
                console.log("loadSelectedReportData", error);
            });
    }

    renderReportAndLoadComments(report: ProtoReport, andBootstrap = false) {
        this.site.selectedSiteReport = report;
        if (andBootstrap) this.bootstrap();
        this.annotServ.pagesMapToKey = new Map<string, ProtoReportPage>();
        this.reportPages.forEach(rawPage => {
            //console.log("SelectReport:ForEach:ReportPages:", p, report);
            this.annotServ.pagesMapToKey.set(rawPage.page_key, rawPage);
        });
        //  this.pages = Array.from(this.annotServ.pagesMapToKey.values());
        //  this.pages = this.pages.sort((a, b) => a.position - b.position);

        this.getChaps();
        console.log("selectReport:", report);
        this.pagesGraphsMap = {};// <---- clear pages content cache
        this.pageCount = this.reportPages.length;
        this.pageLoaded = 0;

        // redirect to page from notif
        if (this.site.protoHebdoPageToRedirect) {
            this.loadJson("", this.site.protoHebdoPageToRedirect);
            this.site.protoHebdoPageToRedirect = 0;
        } else {
            // or load cover
            if (!this.currentPage) this.loadJson("", 1);
        }
        this.annotServ.loadComments(null, true);
    }

    loadJson(dir: string = "", pagePosition: number = 0) {
        if (!this.reportPages || !this.reportPages.length) {
            console.log("loadJson:this.pages EST NULL", this.reportPages);
            console.log("loadJson", dir, pagePosition);
            return;
        }
        this.annotServ.selectedAnnotationUID = null;
        this.pdfSource = null;
        if (pagePosition) this.currentPageIndex = Number(pagePosition);
        else {
            if (this.currentPageIndex < 0) this.currentPageIndex = 0;
            pagePosition = this.currentPageIndex;
        }
        // if (this.currentPageIndex < -1) this.currentPageIndex = -1;

        // console.log("loadJson", this.currentPageIndex, this.pages);
        this.currentPage = this.reportPages.find(p => p.position === Number(pagePosition));
        if (!this.currentPage) {
            console.log("loadJson page NOT found", this.currentPageIndex, pagePosition, this.pages);
            this.myapp.showError("Page introuvable: " + pagePosition);
        } else {
            this.showList = false;
            // console.log("loadJson:", this.currentPage);
            this.annotServ.navigateToNewPage(this.currentPage);
            this.wrapObserver(this.currentPage).subscribe(resp => {
                this.parseAndRenderPage();
            });
        }
        // console.log("loadJson:" + this.currentPageIndex, this.currentPage, this.annotServ.visualKeyToPlotIndexMap);
    }

    parseAndRenderPage(overridedData = null) {
        if (!this.currentPage) {
            console.log("parseAndRenderPage::: this.currentPage IS NULL", this.currentPageIndex, this.rawPages);
            return;
        }
        // console.log("parseAndRenderPage:", this.pagesGraphs, this.currentPageIndex);
        this.currentPageIndex = this.currentPage.position;
        if (!this.pagesGraphsMap[this.currentPageIndex]) {
            this.myapp.showMessage("Page pas encore chargée");
            return;
        }
        if (!this.pagesGraphsMap[this.currentPageIndex].body) {
            console.log("parseAndRenderPage:Body VIDE----", this.pagesGraphsMap[this.currentPageIndex]);
            this.myapp.showMessage("Page vide");
            this.pageRawJson = null;
            this.graphsAdapted = [];
            this.graphs = [];
            return;
        }

        this.pageRawJson = this.pagesGraphsMap[this.currentPageIndex].body;
        this.graphsAdapted = [];
        if (overridedData) {// compare with old page
            this.pageRawJson = overridedData;
        }
        this.graphs = Object.keys(this.pageRawJson)
            .filter(key => key !== 'metas')// remove metas key from raw_visuals added after migration
            .map(key => {
                    let o = this.pageRawJson[key];
                    o['key'] = key;
                    return o;
                }
            );
        this.highLightSelectedChapter();
        this.detectClientDimensions();
        this.resetFullScreen();

        for (let j = 0; j < 8; j++) {
            if (this.graphs[j]) {
                const el = Optimise.fixRawGraphData(this.graphs[j]);
                //console.log("ADAPTTTT", j, this.graphs[j], el);
                this.cellsWidth[j] = (100 / this.currentPage.shape.cols) * el.shape.cols;
                if (el.plotData.layout) {
                    el.plotData.layout.annotations = [];
                    this.graphsTitlesMap.set(el.key, el.plotData.layout['title']);
                }
                this.graphsAdapted[j] = el;

                this.graphsLayoutForPrint[j] = el.plotData.layout;

                // map plotIndex to visual_key
                this.annotServ.assignVisualKeyToGraphData(el, el.key);// <-- keep a plot cache in AnnotService
                this.annotServ.assignVisualKeyToPlotIndex(el.key, j);
                this.annotServ.annotationShow(el.key, 'parseAndRenderPage');
                // console.log("parseAndRenderPage", j, el);

                if (el.plotData.layout) {
                    if (!this.graphsTitlesMap.has(el.key)) {
                        this.graphsTitlesMap.set(el.key, el.plotData.layout['title']);
                        if (typeof el.plotData.layout['title'] === 'object') this.graphsTitlesMap[el.key] = '';
                        this.graphsAdapted[j].plotData.layout['title'] = "";
                    }
                } else {
                    //console.log("layout", JSON.stringify(el));
                }
            } else {
                this.graphsAdapted[j] = {data: null, layout: null, origin: null, shape: null};
            }

        }

        if (this.currentPage && this.currentPage.position === 1)
            this.sidenav1.toggle(true);
        this.resetFullScreen();
    }

    wrapObserver(page: ProtoReportPage, date: string = null): Observable<ObsWrapper> {
        return new Observable<ObsWrapper>(observer => {
            if (this.pagesGraphsMap[page.position] && !date) {
                observer.next({resp: this.pagesGraphsMap[page.position], type: page.position.toString()});
                observer.complete();
            } else {
                this.pleaseWait = true;
                let dateToShow = this.site.selectedSiteReport.date_str;
                if (date) dateToShow = date;//provided to compare pages
                //console.log("wrapObserver::getPageVisuals()  :", this.site.selectedSiteReport.date_str, page, date);
                this.api.getPageVisuals(page.domain_ref, dateToShow, page.page_key, page.position)
                    .subscribe(resp => {
                        if (!resp) {
                            console.error('getPageVisuals', "resp null", resp);
                            observer.complete();
                            return;
                        }
                        this.pleaseWait = false;
                        this.regenUrl = resp.url;
                        if (resp.status == 0) {
                            console.error("GetPageVisual:: Not json fount", this.regenUrl);
                        }
                        if (resp.body && resp.body['metas']) {
                            const metas = resp.body['metas'];
                            if (metas && metas['genDuration'])
                                this.pagesGenDurationMap[page.page_key] = {
                                    genDuration: metas['genDuration'],
                                    genStart: metas['genStart'],
                                    genEnd: metas['genEnd'],
                                };
                        } else {
                            console.error("Body is null", resp);
                        }
                        this.pagesGraphsMap[resp.pos] = resp;
                        this.pagesGraphsArray.push(page.position);
                        observer.next({resp, type: resp.pos.toString()});
                        observer.complete();
                        // console.log("getPageVisuals()" + resp.pos, resp);
                    }, err => {
                        console.log(" wrapObserver() AFTER---ERROR ::" + page.position, err);
                    });
            }
        });
    }

    getChaps() {
        const titlesArrayChunks = {};
        this.reportPages.forEach((page, ind) => {
            if (page.title)
                titlesArrayChunks[page.position] = page.title.split(" - ").map(s => s.trim());
            else {
                if (page.position > 1)
                    console.error("Page title null", page.title, page);
            }
        });
        // console.log("titlesArrayChunks", titlesArrayChunks);
        const localLevelsPages = [];
        const allItems = [];

        Object.keys(titlesArrayChunks).forEach((pageNum, index) => {
            const rawTitlesChunks = titlesArrayChunks[pageNum];

            const item = new TreeItem();

            item.label = rawTitlesChunks[rawTitlesChunks.length - 1];
            if (rawTitlesChunks[rawTitlesChunks.length - 2])
                item.parent = rawTitlesChunks[rawTitlesChunks.length - 2];
            if (rawTitlesChunks[rawTitlesChunks.length - 3])
                item.parent_parent = rawTitlesChunks[rawTitlesChunks.length - 3];

            item.position = parseInt(pageNum, 10);
            item.hash = H.getMd5(item.label + item.parent + item.parent_parent + item.position);

            allItems.push(item);
        });

        Object.keys(titlesArrayChunks).forEach((pageNum, index) => {
            const pagechunks = titlesArrayChunks[pageNum];
            for (let i = 0; i <= index; i++) {//iterate on page depth
                if (i === 0) {
                    const pageLabel = pagechunks[0];
                    if (!localLevelsPages[pageLabel]) localLevelsPages[pageLabel] = {p: pageNum, items: {}};
                }
                if (i === 1 && pagechunks.length > 1) {
                    const pageLabel = pagechunks[1];
                    const parent = pagechunks[0];
                    if (!localLevelsPages[parent].items[pageLabel])
                        localLevelsPages[parent].items[pageLabel] = {p: pageNum, items: {}};
                }
                if (i === 2 && pagechunks.length > 2) {
                    const pageLabel = pagechunks[2];
                    const parent1 = pagechunks[0];
                    const parent2 = pagechunks[1];
                    if (!localLevelsPages[parent1].items[parent2].items[pageLabel])
                        localLevelsPages[parent1].items[parent2].items[pageLabel] = {p: pageNum, items: {}};
                }
            }
        });

        this.pagesTree = allItems;
        this.levelsPages = localLevelsPages;
        return localLevelsPages;
    }

    gotoPage(dir: string) {
        if (dir === 'right') {
            this.currentPageIndex++;
            if (this.currentPageIndex > this.rawPages.length) {
                this.currentPageIndex = 1;
            }
            this.loadJson();
        }
        if (dir === 'left') {
            this.currentPageIndex--;
            if (this.currentPageIndex < 1)
                this.currentPageIndex = 1;
            this.loadJson();
        }
    }

    /*****Events*****/
    selectEvent(ev: SiteEvent) {
        console.log('selectevent', ev);
        if (ev.wat === 'share-proto-hebdo') {
            this.site.currentSiteEvent = ev;
            this.myapp.openBS_Mailer();
        }

    }

    initShareEvent() {
        this.site.currentSiteEvent = new SiteEvent({});
        this.site.currentSiteEvent.site_name = this.site.clientSite.ref;
        this.site.currentSiteEvent.uid_site = this.site.clientSite.uid;
        this.site.currentSiteEvent.subject = this.site.clientSite.name + ' - Protocole hebdo. sem. ' + this.site.selectedSiteReport.week;
        this.site.currentSiteEvent.body = this.myapp.k.inMailerProtoHebdoText + this.myapp.user_display_name_short + " \n" +
            this.myapp.user.office;
        this.site.currentSiteEvent.wat = 'share-proto-hebdo';
        this.site.currentSiteEvent.target = [];
        this.site.currentSiteEvent.related_data = {
            //link: this.myapp.router.url,
            link: "/proto-hebdo/" + this.site.clientSite.uid + "/" + this.site.selectedSiteReport.year + "/" + this.site.selectedSiteReport.week,
            week: this.site.selectedSiteReport.week,
            reportStatus: this.site.selectedSiteReport.pub_status,
            reportDate: this.site.selectedSiteReport.date_str,
            reportUID: this.site.selectedSiteReport.uid
        };
    }

    test(i: number) {
        // console.log('PLOT', this.graphsAdapted[i]);
        return;
        if (!this.graphsAdapted[i]) return;
        if (!this.graphsAdapted[i].plotData) return;
        const blocPlot = this.plotBloc.nativeElement.querySelector("[id='bloc-plot-" + i + "'] .holder");
        if (!blocPlot) return;
        this.plotlyServ.newPlot(blocPlot,
            this.graphsAdapted[i].plotData.data,
            this.graphsAdapted[i].plotData.layout,
            this.defaultConfig).then(r => {
            //console.log(r);
        });
    }

    resetFullScreen() {
        if (!this.plotBloc) return;
        for (let i = 0; i < 90; i++) {
            setTimeout(() => {
                this.test(i)
            }, 5)
            const blocPlot = this.plotBloc.nativeElement.querySelector("[id='bloc-plot-" + i + "']");
            if (this.graphsAdapted[i] && this.graphsAdapted[i].plotData
                && this.graphsAdapted[i].plotData.layout && this.currentPage && this.currentPage.shape
            ) {
                this.graphsAdapted[i].plotData.layout['height'] =
                    (this.clientHeight - 30) * this.graphsAdapted[i].shape.rows / this.currentPage.shape.rows;
                this.graphsAdapted[i].plotData.layout['width'] = this.clientWidth * this.cellsWidth[i] / 100;
                this.graphsAdapted[i].plotData.layout['title'] = undefined;
                this.graphsAdapted[i].plotData.layout['margin'] = this.getMargins();
                this.graphsAdapted[i]['legend'] = {
                    font: {
                        family: 'sans-serif',
                        size: 120,
                        color: '#FF0000'
                    },
                    y: 1,
                    yanchor: "bottom",
                    orientation: 'h',
                    bgcolor: '#ffffff',
                    bordercolor: '#000000',
                    borderwidth: '1px'
                };
            }
            if (this.graphsAdapted[i]) {
                const gr = this.plotBloc.nativeElement.querySelector(".plot-" + i);
                if (blocPlot) {
                    blocPlot.classList.remove("fs");
                    blocPlot.classList.remove("bloc-plot-full");
                }
                if (gr) {
                    gr.classList.add("plotly-plot");
                    gr.classList.remove("fs");
                }
            }

            if (blocPlot) {
                blocPlot.classList.add("bloc-plot");
                blocPlot.classList.remove("bloc-plot-full");
            }
        }
        this.fsMode = false;
    }

    /*
    SIZING
     */
    fullScreenPlot(plotIndex: number) {
        if (this.fsMode)
            this.resetFullScreen();
        else {
            const plotsCount = this.graphsAdapted.filter(it => it.data).length;
            const blocPlot = this.plotBloc.nativeElement.querySelector("[id='bloc-plot-" + plotIndex + "']");
            const gr = this.plotBloc.nativeElement.querySelector("[id='" + this.graphsAdapted[plotIndex].key + "']");
            if (gr && plotsCount > 1) {
                console.log('fullScreenPlot', plotsCount, this.graphsAdapted[plotIndex], this.graphsAdapted);
                gr.classList.remove("plotly-plot");
                gr.classList.add("fs");
                blocPlot.classList.add("bloc-plot-full");
                blocPlot.classList.add("fs");
                // this.plotHolder.nativeElement.style.height = this.clientHeight + 'px';
                this.graphsAdapted[plotIndex].plotData.layout['width'] = this.plotBloc.nativeElement.clientWidth;
                this.graphsAdapted[plotIndex].plotData.layout['height'] = this.clientHeight;
                this.graphsAdapted[plotIndex].plotData.layout['title'] = this.graphsTitlesMap[gr.key];
                this.graphsAdapted[plotIndex].plotData.layout['margin'] = this.getMargins(true);
            }
            this.fsMode = true;
        }

    }

    detectClientDimensions() {
        const plot = document.getElementById('plot-holder');
        if (!plot) return;
        this.clientWidth = plot.clientWidth - 20;
        if (this.clientWidth > 1600) this.clientWidth = 1600;
        // if (this.plotHolder)  this.plotHolder.nativeElement.style.maxWidth = this.clientWidth+'px';
        this.clientHeight = window.innerHeight - 150;
        // console.log('detectClientDimensions', this.clientWidth, 'x', this.clientHeight,   this.plotHolder.nativeElement.clientWidth, this.plotHolder.nativeElement.clientHeight);
        // this.clientHeight = plot.clientHeight;
    }

    getMargins(fs = false) {
        if (fs)
            return {pad: 0, t: 5, b: 100, l: 45, r: 45};
        else
            return {pad: 0, t: 0, b: 55, l: 45, r: 80};
        //return {pad: 0, t: 5, b: 55, l: 45, r: 45};
    }

    /*
    Printing
     */
    preparePrint() {
        if (this.sideNavMode !== "print") {
            this.sidenav2.open();
            this.sideNavMode = "print";
        } else {
            this.sideNavMode = "";
            setTimeout(() => {
                this.loadJson('', this.currentPage.position);
                this.detectClientDimensions();
                this.resetFullScreen();
            }, 200);

        }
    }

    addPageToPrint() {
        if (!this.currentPage)
            this.myapp.showError("Pas de page selectionnée");
        else
            this.pagesToPrint.set(this.currentPage.position, this.currentPage);
    }

    removePageFromPrint(position) {
        this.pagesToPrint.delete(position);
        if (this.pagesToPrint.size === 0) this.sideNavMode = "";
    }

    getPagesToPrint() {
        return Array.from(this.pagesToPrint.values());
    }

    downloadAsPDF(withComments = false) {
        // const doc = new jsPDF();
        const plotBlocs = this.pdfBloc.nativeElement.querySelectorAll(".page");
        let finalHtml = '';
        plotBlocs.forEach(page => {
            finalHtml += page.outerHTML;
        });
        console.log("print", finalHtml);
        let title = "Rapport du:" + this.site.selectedSiteReport.date_str;

        if (withComments) {
            title = this.site.clientSite.name + ' | ';
            title += "Historique des commentaires du protocole hebdomadaire";
            if (this.annotServ.selectedYear > 0) title += " | Ann&eacute;e: " + this.annotServ.selectedYear;
        }
        this.api.printProtoHebdo(finalHtml, title, withComments)
            .subscribe(r => {
                this.pdfSource = this.api.getLink(r['body'], true);
                if (withComments)
                    H.openUrlNewTab(this.pdfSource);
                console.log("print-resp", r, this.pdfSource);
            });
    }

    /* OTHER */
    highLightSelectedChapter() {
        if (!this.currentPage) return;
        document.querySelectorAll('.selected-chapter').forEach(i => {
            i.classList.remove('selected-chapter');
        });
        if (this.pagesTree[this.currentPage.position]) {
            const currentPageInTree = this.pagesTree[this.currentPage.position];
            const selectedPage = document.getElementById(H.getMd5(currentPageInTree.label));
            if (selectedPage)
                selectedPage.parentElement.parentElement.parentElement.classList.add('selected-chapter');
            if (currentPageInTree.parent) {
                const selectedItem = document.getElementById(H.getMd5(currentPageInTree.parent));
                if (selectedItem)
                    selectedItem.parentElement.parentElement.parentElement.classList.add('selected-chapter');
            }
            if (currentPageInTree.parent_parent) {
                const selectedItem = document.getElementById(H.getMd5(currentPageInTree.parent_parent));
                if (selectedItem)
                    selectedItem.parentElement.parentElement.parentElement.classList.add('selected-chapter');
            }
        }
    }

    closeContextMenu($event = null) {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
            return false;
        }
        return true;
    }

    overrideContextMenu(event, plotIndex) {
        return false;
    }

    /*

     */
    loadSamePageOfDate(date, $event) {
        $event.target.focus();
        this.compareTargetDate = date;

        this.wrapObserver(this.currentPage, date).subscribe(resp => {
            this.parseAndRenderPage();
        });
    }

    republishReportOfdate(reportDate: string) {
        const url = 'https://www.optimigration.ch/storage/deploy.php?ref=' + this.site.clientSite.ref + '&date=' + reportDate;
        console.log("republishReportOfdate:start", reportDate, url);
        this.api.sendGet(url)
            .subscribe(resp => {
                console.log("SrepublishReportOfdate", resp);
            }, error => {
                this.myapp.showError("Proto-hebdo de la date " + reportDate + " non publié");
                console.log("Send get Error", error);
            });
    }

    regenFromJarAlias(alias) {
        // calls publish-generate-from-local
        const url = 'https://www.optimigration.ch/app/publish/from-local?alias=' + alias + "&ref=" + this.site.clientSite.ref;
        this.site.api.sendGet(url)
            .subscribe(resp => {
                console.log("regenFromJar::Resp", this.regenUrl, resp);
                //this.site.loadReportsAndDetectNew();
            });
    }

    regenFromJar(url: string) {
        if (!url) {
            console.error('regenFromJar::url de regeneration null', url);
        }
        this.site.api.sendGet(url)
            .subscribe(resp => {
                console.log("regenFromJar::Resp", url, resp);
                this.selectReport(true);
            });
    }

    doneLoading($e) {
        //console.log("doneLoading", Date.now() - this.timestart, $e);
    }

    getKeys(arr) {
        return Object.keys(arr);
    }

    ngOnDestroy() {
        this.rawPages = null;
        this.pageRawJson = null;
        this.annotServ.pagesMapToPos = new Map<string, ProtoReportPage>();
        this.annotServ.pagesKeyToPosition = new Map<string, number>();
        this.annotServ.resetAllSelections();
        this.pageCount = 0;
        this.pageLoaded = 0;
        this.pagesGraphsMap = {};
        if (this.routeSubscription) this.routeSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.layout.publishLayoutChange({sidebarStyle: 'closed'});
        // console.log("this.plotHolder", this.plotHolder);
        this.annotServ.triggerActionsObs.subscribe(obj => {
            if (!obj) return;
            if (obj.sidenavChapterToggle !== undefined && this.sidenav2) {
                // this.sidenav1.toggle(obj.sidenavChapterToggle);
            }
            if (obj.sidenavToggle !== undefined && this.sidenav2) {
                this.sidenav2.toggle(obj.sidenavToggle);
            }
            if (obj.closeContextMenu) {
                this.closeContextMenu();
            }
            if (obj.commentReplyMode) {
                this.commentReplyMode = obj.commentReplyMode;
            }
            if (obj.sideNavMode) {
                this.sideNavMode = obj.sideNavMode;
            }
            if (obj.gotoPagePos) {
                this.loadJson('', obj.gotoPagePos);
            }
            if (obj.selectComment) {
                if (this.annotsWithNotifs.includes(obj.selectComment.uid)) {
                    const notifToDel = this.site.notifsOfCurrentsite.find(it => it.targetUID === obj.selectComment.uid);
                    this.myapp.rdb.object('notifs/' + this.myapp.user.uid + '/' + notifToDel.key).remove().then(done => {
                        console.log("Seen", done);
                    })
                }
                this.annotServ.selectComment(obj.selectComment);
            }
            if (obj.showAnnotations) {
                const {plotIndex, annotations} = obj.showAnnotations;
                if (this.graphsAdapted[plotIndex]
                    && this.graphsAdapted[plotIndex].plotData
                    && this.graphsAdapted[plotIndex].plotData.layout
                ) {
                    this.graphsAdapted[plotIndex].plotData.layout['paper_bgcolor'] = 'rgba(255,255,255,1)';
                    if (!this.graphsAdapted[plotIndex]
                        || !this.graphsAdapted[plotIndex].plotData
                        || !this.graphsAdapted[plotIndex].plotData.layout) {
                        console.error("Ne possède pas de plotData: ", this.graphsAdapted[plotIndex], ' Index:', plotIndex);
                        return;
                    }
                    this.graphsAdapted[plotIndex].plotData.layout.annotations = annotations.map(it => {
                        it = new ReportAnnotation(it);
                        it.styling(this.annotServ.selectedComment);
                        return {...it};
                    });
                } else {
                    //console.error("Annotations orphelins", annotations, plotIndex, this.graphsAdapted[plotIndex], this.graphsAdapted);
                    //this.myapp.showError("Le graphique d'index: " + plotIndex + " est introuvable. " + this.graphsAdapted.length);
                }

            }
            if (obj.do && obj.do === 'highLight') {
                console.log("triggerActionsObs", obj, this.graphsAdapted[obj.plotIndex].layout)
                if (this.graphsAdapted[obj.plotIndex]
                    && this.graphsAdapted[obj.plotIndex].plotData
                    && this.graphsAdapted[obj.plotIndex].plotData.layout) {
                    if (obj.dir === "focusin")
                        this.graphsAdapted[obj.plotIndex].plotData.layout['paper_bgcolor'] = 'rgba(200,200,200,0.4)';
                    if (obj.dir === "focusout")
                        this.graphsAdapted[obj.plotIndex].plotData.layout['paper_bgcolor'] = 'rgba(255,255,255,1)';
                }

            }
        });
        this.detectClientDimensions();
    }

    /*
    HELPERS
    */
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.detectClientDimensions();
        this.resetFullScreen();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.code === 'KeyP' && event.altKey) {
            this.addPageToPrint();
        }
        if (event.code === 'KeyM' && event.altKey) {
            this.sidenav1.toggle();
        }
        if (event.code === 'KeyC' && event.altKey) {
            this.sidenav2.toggle();
        }

        // navigate with arrows when coomment listing mode and not replying to comment
        if (this.sideNavMode === 'comment' && !this.commentReplyMode) {
            if (event.code === 'ArrowRight') {
                this.gotoPage('right');
            }
            if (event.code === 'ArrowLeft') {
                this.gotoPage('left');
            }
        }

    }
}

export class TreeItem {
    label: string;
    position: number;
    hash: string;
    parent: string;
    parent_parent: string;
    items: TreeItem[] = [];
}
