import {Injectable, Optional, SkipSelf} from '@angular/core';

import 'firebase/firestore';
import {BehaviorSubject, forkJoin, concat, from, Observable, Observer, Subscription} from 'rxjs';
import {ApiService} from "./api.service";
import {H} from "../helpers/H";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {Pdm} from "../models/Pdm";
import {PdmDataSummary} from "../models/PdmDataSummary";
import {PdmDataRow} from "../models/PdmDataRow";
import {K} from "../models/K";
import moment from "moment";
import {AppService} from "./app.service";
import {ClientSite, SiteReleveDelay, SiteTariffConfig, SiteTariffData, StorageDocument, User} from "../models/models";
import {Action, Chapter} from '../models/Action.model';
import {EnergyAgent, EnergyStatsItem, Metric} from "../models/EnergyStats.model";
import {ProtoReport} from "../models/ProtoHebdo";
import {SiteEvent} from "../models/Notifs";
import {BmensItemRow} from "../models/Bmens.model";
import {PdmYearlyCalcedData, PdmYearlyConfig} from "../models/PdmYearConfig";
import {PdmSignature} from "../models/PdmSignature";

@Injectable(
    {providedIn: "root"}
)
export class SiteService {
    public static MAX_SITE_LOADING_STATUS = 4;
    public static ACTION_SAVED = 'ACTION_SAVED';
    public static PDMS_LOADED = 'PDMS_LOADED';
    public static PDMS_DATA_LOADED_METEO_INTO_SERVER = 'PDMS_DATA_LOADED_METEO_INTO_SERVER';
    public static PDMS_DATA_LOADED_VAREXPL_INTO_SERVER = 'PDMS_DATA_LOADED_VAREXPL_INTO_SERVER';
    public static PDMS_DATA_POPULATED = 'PDMS_DATA_POPULATED';
    public static PDMS_YEARLY_CONFIG_CHANGED = 'PDMS_YEARLY_CONFIG_CHANGED';
    public static PDMS_YEARLY_CONFIG_SELECTED = 'PDMS_YEARLY_CONFIG_SELECTED';
    public static PDMS_YEARLY_PLOT_DATA_CALCED = 'PDMS_YEARLY_PLOT_DATA_CALCED';

    public K = K;
    public siteLoadingStatus: BehaviorSubject<number>;
    public statmensLoadingStatus: BehaviorSubject<number>;
    public siteLoadingStatusInt = 0;
    public statmensLoadingStatusInt = 0;
    public siteSpecificEventTriggerer: BehaviorSubject<string>;
    public currentSiteEvent: SiteEvent = new SiteEvent({});

    public uid: string;
    public clientSite: ClientSite = null;
    public responsibles: User[] = [];
    public responsiblesMap: Map<string, User> = new Map<string, User>();
    public responsiblesMapToOldUid: Map<string, User> = new Map<string, User>();
    public actions: Action[] = [];
    public chapters: Chapter[] = [];
    public chaptersMap: Map<string, Chapter> = new Map<string, Chapter>();
    public selectedAction: Action = null;
    // public filteredActions: Action[] = [];

    public selectedSiteReport: ProtoReport;
    public selectedSiteReportsToValidate: ProtoReport[] = [];
    public selectedSiteReports: ProtoReport[] = [];
    public selectedSiteReportsJars: string[] = [];
    tried_loading_pdms = false;
    year: number;
    refYear: number; /// <-- IMPORTANT, this value, when null, all statmens rendering stops for the selectedPdm,
    // being populated indicated that PdmYearlyConfig was properly loaded

    availableYearsInRows: number[] = [];
    availableFluids: string[] = [];

    // calendar + releve delays
    public siteCalendar = [];
    public siteFinalCalendarMap: Map<string, SiteReleveDelay> = new Map<string, SiteReleveDelay>();
    public releveDelaysMap: Map<string, SiteReleveDelay> = new Map<string, SiteReleveDelay>();

    public selectedFluid: string = null;
    public selectedMonthIndex: number = 11; // 0-11
    public dataSummaryMap: Map<string, PdmDataSummary> = new Map<string, PdmDataSummary>(); // for 5 years including report year
    public tariffValueForYears: Map<string, number> = new Map<string, number>();// new to keep values for all years for a tarif, valorisation multannuelle actions

    // actions and suivi iteratif
    public defaultTariffToUIDMap: Map<string, EnergyStatsItem> = new Map<string, EnergyStatsItem>();//any= object maping year to tariff
    public agentMetricToTarifsMap: Map<string, any> = new Map<string, any>();//any= object maping year to tariff

    /// TODO: Remove next time and related lines
    public energyAgentsMap: Map<string, EnergyAgent> = new Map<string, EnergyAgent>();
    public metricsMap: Map<string, Metric> = new Map<string, Metric>();
    public metricsMapByMid: Map<string, Metric> = new Map<string, Metric>();

    public pdms: Pdm[];
    public selectedPdm: Pdm;
    public selectedPdmYearlyPlotDataMap: Map<number, PdmYearlyCalcedData> = new Map<number, PdmYearlyCalcedData>();
    public selectedConfigForPdmAndYear: PdmYearlyConfig = null;
    public selectedConfigForPdmAndREFYear: PdmYearlyConfig = null;

    public selectedSiteEpDataBase: any;
    public varsExplFromRegs = {};

    public reportsObservable: Observable<any>;
    public protoHebdoPageToRedirect = 0;

    public docsChapters: string[] = [];
    public documents: StorageDocument[] = [];
    public documentsMap: Map<string, StorageDocument> = new Map<string, StorageDocument>();
    public seriesData = {};
    public currYear = new Date().getFullYear();  // returns the current year;
    public itemsToPreloadObs = [];


    constructor(public api: ApiService,
                public myapp: AppService,
                public toastr: ToastrService,
                public activatedRoute: ActivatedRoute,
                // public ws: WebSocketService,
                public router: Router,
                public http: HttpClient) {

        this.statmensLoadingStatus = new BehaviorSubject<number>(0);
        this.siteLoadingStatus = new BehaviorSubject<number>(0);
        this.siteSpecificEventTriggerer = new BehaviorSubject<string>('');
        this.myapp.appInitStatus.subscribe(status => {
            console.info("appInitStatus", status);
            if (status === 2) this.bootstrap();// when user and sites loaded in AppService bootstrap this service
            // if (status === 3) this.myapp.run();// everything finished loading , redirect to target route
        });
    }

    test() {
        // this.ws.message(H.randomInt(100, 9999) + " New");
    }

    get notifsOfCurrentsite() {
        return this.myapp.notifs.filter(it => it.site === this.uid);
    }

    bootstrap() {

        if (!this.year)
            this.year = moment().year();
        const urlParams = this.myapp.getUrlParams();

        /*
        this.myapp.router.events.subscribe(ev => {
            // const segs = ev['snapshot']['_urlSegment']['segments'];
            console.log("Event", ev);
        });*/

        /*
        this.activatedRoute..forEach(ev => {
            // const segs = ev['snapshot']['_urlSegment']['segments'];
            console.log("Event666666", ev);
        });
        console.log("Site::-------------:bootstrap()", "Start", urlParams,
            this.myapp.clientSiteList.filter(it => !it.done).map(it => it.ref),
            this.myapp.clientSiteList
        );*/

        if (urlParams.length > 2) {
            const uidSiteFromUrl = urlParams[1];
            const foundSite = this.myapp.clientSiteList.find(it => it.uid === uidSiteFromUrl.path);
            console.log("Recherche du batiment depuis url: " + uidSiteFromUrl, foundSite);

            if (foundSite) {
                console.log("Recherche du batiment depuis url: " + uidSiteFromUrl, '.....Trouvé', foundSite.ref);
                this.selectSite(foundSite);
            }
        }

        // if no site from url and no site already initialized (runing session)
        if (!this.clientSite) {
            const selectedSiteUID = localStorage.getItem('selectedDomainUID');//
            if (selectedSiteUID) {
                const foundSite = this.myapp.clientSiteList.find(it => it.uid === selectedSiteUID);
                if (foundSite) {
                    console.log("Chargement du batiment chargé à la session précédente:", foundSite.ref);
                    this.selectSite(foundSite);
                }
            }
        }
        const selectedFluid = localStorage.getItem('selectedFluid');//
        if (selectedFluid) {
            this.selectFluid(selectedFluid);
        }

        if (this.clientSite) {
            this.uid = this.clientSite.uid;
            this.siteCalendar = [];
            this.generateCalendar(this.year - 1);
            this.generateCalendar(this.year);
            // console.log(" this.siteCalendar: --here", this.siteCalendar);
            //
            // this.siteLoadingStatusInt = 0;
            this.siteLoadingStatus.next(0);
            this.myapp.siteResponsibleSubs = this.loadResponsibles();
            this.itemsToPreloadObs = [
                new Observable((observer) => {
                    this.loadMetrics(observer);
                }),//metrics
                new Observable((observer) => {
                    this.loadDocuments(observer);
                }),//loadDocuments and their chapters
                new Observable((observer) => {
                    this.loadActions(observer);
                }),//loadReportsAndDetectNew
                new Observable((observer) => {
                    this.loadReportsAndDetectNew(observer);
                }),

                /*
                                new Observable((observer) => {
                                    this.loadSitePdmsYearlyConfigs(observer);
                                }),//pdmSiteYearlyConfig

                                new Observable((observer) => {
                                    this.loadSitePdms(observer);
                                }),//sitePdms

                                new Observable((observer) => {
                                    this.loadPdmEpLinkData(observer);
                                }),//pdmEpLinkData
                                */
            ];
            concat(...this.itemsToPreloadObs).subscribe(resp => {
                if (this.siteLoadingStatusInt === SiteService.MAX_SITE_LOADING_STATUS)
                    this.siteLoadingStatusInt = 0;// <-- prepare counter for next bootstrap
                this.siteLoadingStatusInt++;
                // console.info('SiteService: concat: ' + this.siteLoadingStatusInt, resp);
                this.siteLoadingStatus.next(this.siteLoadingStatusInt);
                if (this.siteLoadingStatusInt === this.itemsToPreloadObs.length)
                    this.myapp.appInitStatus.next(3);
            });
        }
    }

    /// TODO: Mark for removal
    loadStatmensConfigs() {
        this.statmensLoadingStatus.next(0);
        this.itemsToPreloadObs = [
            /*
            new Observable((observer) => {
                this.loadSitePdmsYearlyConfigs(observer);
            }),//pdmSiteYearlyConfig
*/
            new Observable((observer) => {
                this.loadSitePdms(observer);
            }),//sitePdms

            new Observable((observer) => {
                this.loadPdmEpLinkData(observer);
            }),//pdmEpLinkData
        ];
        concat(...this.itemsToPreloadObs).subscribe(resp => {
            if (this.siteLoadingStatusInt === SiteService.MAX_SITE_LOADING_STATUS)
                this.siteLoadingStatusInt = 0;// <-- prepare counter for next bootstrap
            this.statmensLoadingStatusInt++;
            console.info('SiteService: loadStatmensConfigs()::concat: ' + this.statmensLoadingStatusInt, resp);
            this.statmensLoadingStatus.next(this.statmensLoadingStatusInt);
            if (this.statmensLoadingStatusInt === this.itemsToPreloadObs.length)
                this.siteSpecificEventTriggerer.next('PDM_CONFIG_LOADED');
        });
    }

    loadDocuments(observer: Observer<any> = null) {
        this.documents = [];
        this.documentsMap = new Map<string, StorageDocument>();
        this.api.getDocuments(this.clientSite.uid).subscribe(res => {
            // this endpoint returns documents AND chapters
            this.documents = [];
            this.documentsMap = new Map<string, StorageDocument>();
            res.body.forEach(d => {
                const _doc = new StorageDocument(d);
                _doc.genObs(this.myapp.storage);
                if (!_doc.storage_metas && _doc.full_path) {
                    console.log("Diagnoze err:", d);
                    _doc._storageMetaSubscription.subscribe(metas => {
                        _doc.storage_metas = metas;
                        this.documentsMap.set(_doc.uid, _doc);
                        this.saveMetasIntoDocumentsBdd(_doc);
                    });
                }
                if (_doc.full_path)
                    this.documentsMap.set(_doc.uid, _doc);
                this.documents = Array.from(this.documentsMap.values());
            });
            // load chapters
            this.docsChapters = [];
            res.chapters.forEach(ch => {
                if (ch !== '.' && ch !== '..')
                    this.docsChapters.push(ch);
            });
            this.docsChapters.sort();
            this.docsChapters.push("Corbeille");
            if (observer) {
                observer.next(this.documents);
                observer.complete();
            }
        });
    }

    getDocByPath(path: string) {
        // return this.siteService.documents.find(it => it.full_path.includes(uid));
        let fullPath = path;
        if (!fullPath.includes('document')) fullPath = 'attachments/' + path;
        let retVal = null;
        this.documents.forEach(doc => {
            if (doc.full_path === fullPath) {
                retVal = doc;
            }
        });
        return retVal;

        // this.myapp.storage.ref(fullPath)
        //     .getMetadata()
        //     .subscribe(metas => {
        //         // console.log("getDocByUID:Metas", path, metas);
        //         if (this.siteService.documentsMap.has(metas.name)) {
        //             metas.epDocUID = this.siteService.documentsMap.get(metas.name).uid;
        //             metas.name = this.siteService.documentsMap.get(metas.name).title;
        //         }
        //         metas.storedPath = path;
        //         this.docsMetas.push(metas);
        //     });

    }

    saveMetasIntoDocumentsBdd(doc) {
        this.api.saveDocument(doc).subscribe(resp => {
            console.log("saveDocument", resp, doc);
            if (resp.status == 1) {
                //this.myapp.showMessage("Document mit à jour avec succès!");
            } else this.myapp.showError("Document non mit à jour. " + resp.errors);
        });
    }

    loadMetrics(observer: Observer<any> = null) {
        if (!this.myapp.auth.user) observer.complete();
        this.api.getMetrics().subscribe(resp => {
            if (resp && resp.body && Array.isArray(resp.body)) {
                resp.body.forEach(rawMetric => {
                    const tempMetric = new Metric(rawMetric);
                    this.metricsMap.set(tempMetric.uid, tempMetric);
                    this.metricsMapByMid.set(tempMetric.mid, tempMetric);
                });
                this.metricsMap = new Map([...this.metricsMap.entries()].sort((a, b) => {
                    return a[1].fluid.localeCompare(b[1].fluid);
                }));
                this.metricsMapByMid = new Map([...this.metricsMapByMid.entries()].sort((a, b) => {
                    return a[1].fluid.localeCompare(b[1].fluid);
                }));
            }
            // console.info("loadMetrics: ", this.metricsMap);
            if (observer) {
                this.myapp.db.collection('agents').get().subscribe(resp => {
                    this.energyAgentsMap = new Map<string, EnergyAgent>();
                    resp.docs.forEach(agent => {
                        const ag = new EnergyAgent(agent.data());
                        this.energyAgentsMap.set(ag.agent_metric, ag);
                    })
                    //console.log("loadMetricsFROM FS: ", this.energyAgentsMap);
                    observer.next(this.metricsMap);
                    observer.complete();
                }, error => {
                    console.error("retrieve agents for firestore failes", error);
                })

            }
        });
    }

    loadReportsAndDetectNew(observer: Observer<any> = null) {
        //console.log("Site::-------------SEND--:::::loadReportsAndDetectNew()====", this.clientSite.ref, this.router.routerState.snapshot.url);
        this.api.getAllReportsForRef(this.clientSite.ref, this.myapp.isAdminOrSuperUser)
            .subscribe(resp => {
                this.selectedSiteReports = [];
                this.selectedSiteReportsToValidate = [];
                if (resp && resp.fromJar) {
                    this.selectedSiteReportsJars = [];
                    resp.fromJar.forEach(jarPath => {
                        this.selectedSiteReportsJars.push(jarPath);
                    });
                }
                if (resp && resp.reports) {
                    resp.reports.forEach(rep => {
                        const repObj = new ProtoReport(rep);
                        // refresh object in memory -> usefull when change pub status
                        if (this.selectedSiteReport && this.selectedSiteReport.uid === repObj.uid) {
                            this.selectedSiteReport = repObj;
                        }
                        if (repObj.pub_status === 'READY')
                            this.selectedSiteReports.push(repObj);
                        else {
                            console.log("Site:selectedSiteReportsToValidate:NOT READY ",
                                this.selectedSiteReportsToValidate.length, repObj)
                            this.selectedSiteReportsToValidate.push(repObj);
                        }
                    });
                } else {
                    console.error("getAllReportsForRef():: failed. Response null", resp);
                }

                this.selectedSiteReports = this.selectedSiteReports
                    .sort((r1, r2) => {
                        return r2.date_str.localeCompare(r1.date_str);
                    });
                if (observer) {
                    observer.next(this.selectedSiteReports);
                    observer.complete();
                }
                // console.log("Site::-------------GET----:::::getAllReportsForRef====", resp, this.selectedSiteReportsToValidate, this.selectedSiteReports);

                // this.resetFieldsAfterSiteSelect();
            });
    }

    loadResponsibles() {
        if (!this.myapp.auth.user) {
            console.log("Cant loadResponsibles() - not connected");
            return;
        }
        return this.myapp.db.collection<any>('users', ref =>
            ref.where('site_access.' + this.clientSite.uid + '.responsability', '>', '')
        ).valueChanges().subscribe(list => {
            this.responsibles = [];
            // console.log('loadResponsibles()', list);
            list.forEach(rawUser => {
                const u = new User(rawUser);
                this.responsibles.push(u);
                this.responsiblesMap.set(u.uid, u);
                this.responsiblesMapToOldUid.set(u.uid_bdd, u);
            });
            this.responsibles = this.responsibles.sort((a, b) => {
                return a.group.localeCompare(b.group);
            });
        }, error => {
            console.error('loadResponsibles()', error);
        });
    }

    selectFluid(fl: string) {
        this.selectedFluid = fl;
        localStorage.setItem('selectedFluid', this.selectedFluid);
    }

    getWeatherStation() {
        let weatherStation = this.clientSite.weather;
        if (weatherStation.includes('.')) {
            const chunks = weatherStation.split(".");
            weatherStation = chunks[0];
        }
        return H.ucfirst(weatherStation);
    }

    /*
    Calendar helpers //mi janvier mi juillet
     */
    parseSiteReleveDelays() {
        //console.log("GEN_calendar START", this.clientSite);
        if (this.clientSite.releve_delays && Array.isArray(this.clientSite.releve_delays)) {
            this.clientSite.releve_delays.forEach(delayItem => {
                // console.log("GEN_calendar", delayItem);
                this.releveDelaysMap.set(delayItem.id, delayItem);
            });
        } else {
            // console.log("GEN_calendar ELSE", this.clientSite.releve_delays);
        }
    }

    generateCalendar(year = null) {
        if (!year) year = this.year;
        this.parseSiteReleveDelays();
        const momentDate = moment([year]);
        if (!this.clientSite.releve_day) return;
        this.K.months.forEach((m, index) => {
            momentDate.endOf('month');
            momentDate.set({weekday: this.K.weedDayToIndex[this.clientSite.releve_day.toLowerCase()]}).subtract(12, 'hours');

            if (momentDate.month() > index) {
                momentDate.subtract(1, 'week');
            }

            const d1 = momentDate.format("YYYY-MM-DD ddd");
            const d1Short = momentDate.format("YYYY-MM-DD");
            momentDate.add(1, 'week');
            const d2 = momentDate.format("YYYY-MM-DD ddd");
            const d2Short = momentDate.format("YYYY-MM-DD");
            momentDate.subtract(1, 'week');

            const endOfMonth = moment([year]).add(index, 'month').endOf('month').subtract(23, 'hours');
            const dist1 = Math.abs(Number(endOfMonth.diff(moment(d1, "YYYY-MM-DD ddd"), 'days', true).toFixed(2)));
            const dist2 = Math.abs(Number(endOfMonth.diff(moment(d2, "YYYY-MM-DD ddd"), 'days', true).toFixed(2)));
            //console.log("relevé: " + index, d1, d2, edited, " Distance de :" + endOfMonth.format("YYYY-MM-DD ddd"), "=>", dist1, dist2);

            let winner = "", winnerShort = "";
            if (dist1 < dist2) {
                winner = d1;
                winnerShort = d1Short;
            } else {
                winner = d2;
                winnerShort = d2Short;
            }

            const idReleve = SiteReleveDelay.getIdFromIndex(year, index);
            this.siteCalendar.push({
                relatedId: idReleve,
                numReleve: (index + 1),
                month: m.toUpperCase(),
                date1: d1, date2: d2,
                endOfMonth: endOfMonth.format("YYYY-MM-DD ddd"),
                distOfD1: dist1, distOfD2: dist2, winner
            });
            if (this.releveDelaysMap.has(idReleve)) {
                const rd = this.releveDelaysMap.get(idReleve);
                console.log("GEN_calendar", idReleve, rd);
                rd.edited = 1; // to differentiate from dates that comes from calendar and distance calculation and dates that are exceptions
                this.siteFinalCalendarMap.set(idReleve, rd);
            } else
                this.siteFinalCalendarMap.set(idReleve, new SiteReleveDelay(year, Number(index + 1), winnerShort));
            momentDate.add(1, 'month');
        });
        //remove duplicates
        const map: Map<string, any> = new Map<string, any>();
        this.siteCalendar.forEach(item => map.set(item.relatedId, item));
        this.siteCalendar = Array.from(map.values());
    }

    // ACTIONS
    loadActions(observer: Observer<any> = null) {
        this.actions = [];
        this.api.getActions(this.clientSite.uid).subscribe(resp => {
            this.actions = [];
            if (resp) {
                resp.body.forEach(a => {
                    const act = new Action(a, this);
                    this.actions.push(act);
                });
                this.chapters = resp.chapters.map(it => new Chapter(it));
                this.chaptersMap = new Map<string, Chapter>();
                this.chapters.forEach(ch => this.chaptersMap.set(ch.uid, ch));
                this.myapp.showMessage("Actions et chapitres chargés " + this.actions.length);
            }

            if (observer) {
                observer.next(this.actions);
                observer.complete();
            }
        }, error => {
            console.log("loadActions", error);
        });
    }

    openAction(action: Action) {
        this.selectedAction = action;
        this.getActionAvailableYearsPerAgentMetric();
        this.myapp.openBottomSheet();
    }

    actionSetChapter(action: Action, chapter: Chapter) {
        console.log("actionSetChapter TODO ", action, chapter);
        action.uid_chapter = chapter.uid;
        this.api.saveAction(action, action.uid).subscribe(resp => {
            if (resp.status === 1) {
                const actionsMap = new Map<string, Action>();
                this.actions.forEach(act => actionsMap.set(act.uid, act));
                actionsMap.set(action.uid, action);
                this.actions = Array.from(actionsMap.values());
                this.myapp.showMessage("Action sauvegardée avec succès!");
            } else {
                this.myapp.showError("Action non sauvegardé");
                console.log("Erreur sauvegarde action", resp.errors);
            }
        });
    }

    permutateChapters(uidChap1: string, uidChap2: string, order1: number, order2: number) {
        this.api.permutateChapters(this.clientSite.uid, uidChap1, uidChap2, order1.toString(), order2.toString()).subscribe(res => {
            this.chapters = res.chapters.map(it => new Chapter(it));
            this.chaptersMap = new Map<string, Chapter>();
            this.chapters.forEach(ch => this.chaptersMap.set(ch.uid, ch));
        });
    }

    updateChapter(uid: string, title: string) {
        const dt = {title: title};
        this.api.saveOrCreateChapter(this.clientSite.uid, JSON.stringify(dt), uid).subscribe(res => {
            console.log('updateChapter', res);
            this.chapters = res.chapters.map(it => new Chapter(it));
            this.chaptersMap = new Map<string, Chapter>();
            this.chapters.forEach(ch => this.chaptersMap.set(ch.uid, ch));
        });
    }

    addChapter(parent: string, displayOrder: string, type: string) {
        const dt = {
            parent: parent,
            display_order: displayOrder,
            uid_site: this.clientSite.uid,
            uid: H.randomInt(1000000, 999999999) + "",
            title: "A modifier",
            type
        };
        this.api.saveOrCreateChapter(this.clientSite.uid, JSON.stringify(dt), '').subscribe(res => {
            this.chapters = res.chapters.map(it => new Chapter(it));
            this.chaptersMap = new Map<string, Chapter>();
            this.chapters.forEach(ch => this.chaptersMap.set(ch.uid, ch));
        });
    }

    // Economies
    getUsages(ofYear = 0): EnergyStatsItem[] {
        if (!this.clientSite) return [];
        if (ofYear > 0) {
            if (this.clientSite.old_energy_stats_map.has(ofYear.toString()))
                return this.clientSite.old_energy_stats_map.get(ofYear.toString()).usage;
        }
        // requesting defaultenergystats
        if (!this.clientSite.old_default_energystats) return [];
        if (!this.clientSite.old_default_energystats.usage) return [];
        return this.clientSite.old_default_energystats.usage;
    }

    getAgentPriceUnit(agentMetric): string {
        const unitCateg = this.energyAgentsMap.get(agentMetric).unit;
        return this.myapp.k.getDefaultCostUnits(unitCateg)
    }

    getMetricUnit(metricAgent) {
        // console.log("getMetricUnit", metricAgent, this.siteService.energyAgentsMap);
        const metricAgentObj = this.energyAgentsMap.get(metricAgent).unit;
        return K.metricUnit[metricAgentObj];
    }

    getEconomy(economies: any): number {
        let retval = 0;
        Object.keys(economies).forEach(econ => {

            if (econ === 'MONEY') retval += Number(economies[econ]);
            else
                retval += this.getAgentPrice(econ) * economies[econ] * 1000 / 100;
        });
        return retval;
    }

    getTariffObj(tarifUID): SiteTariffConfig {
        let retVal = null;
        if (!this.clientSite.tariff_config) return retVal;
        this.clientSite.tariff_config.forEach(it => {
            if (it.uid === tarifUID) {
                // console.log("site:getRefTariff", usageUID, it);
                // retVal = it.price * 1000 * 100;
                retVal = it;
                // if (it.isWater) retVal = retVal / 1000 / 100;
                // if (it.isWater) retVal = retVal * 1000 * 100;
            }
        });
        return retVal;
    }

    getTariffValueForYear(tarifUID, year: number = 0): number {
        let retVal = -1;
        const tarifObj = this.getTariffObj(tarifUID);
        if (!tarifObj) return retVal;
        if (year === 0) year = this.clientSite.year_tariff_default;
        if (year === 1) year = this.clientSite.year_tariff_display;

        if (this.clientSite.usage_map.has(year + '-' + tarifUID)) {
            const tariffData = this.clientSite.usage_map.get(year + '-' + tarifUID)
            retVal = tariffData.priceAdapted;
        }
        this.getTariffValueForAllYears(tarifUID);
        // console.log("SERVICE:::", tarifUID, year, this.clientSite);
        return retVal;
    }

    getTariffValueForAllYears(tarifUID): number {
        let retVal = -1;
        const tarifObj = this.getTariffObj(tarifUID);
        if (!tarifObj) return retVal;
        for (let y = tarifObj.year_start; y <= tarifObj.year_end; y++) {
            if (this.clientSite.usage_map.has(y + '-' + tarifUID)) {
                const tariffData = this.clientSite.usage_map.get(y + '-' + tarifUID)
                retVal = tariffData.priceAdapted;
                this.tariffValueForYears.set(y + '-' + tarifUID, retVal);
            }
        }

        // console.log("SERVICE::getTariffValueForAllYears:", tarifUID, this.tariffValueForYears);
        return retVal;
    }

    getTariffDataForYear(tarifUID, year: number = 0): SiteTariffData {
        let retVal = null;
        const tarifObj = this.getTariffObj(tarifUID);
        if (!tarifObj) return retVal;
        if (year === 0) year = this.clientSite.year_tariff_default;
        if (year === 1) year = this.clientSite.year_tariff_display;

        if (this.clientSite.usage_map.has(year + '-' + tarifUID)) {
            const tariffData = this.clientSite.usage_map.get(year + '-' + tarifUID)
            retVal = tariffData;
        }
        // console.log("SERVICE:::", tarifUID, year, this.clientSite);
        return retVal;
    }

    getActionAvailableYearsPerAgentMetric() {
        if (!this.selectedAction) return;
        if (this.clientSite.tariff_config && Array.isArray(this.clientSite.tariff_config)) {
            this.clientSite.tariff_config.forEach(conf => {
                // const range = H.arrayFromRange(conf.year_start, conf.year_end);
                // console.log("Range", range);
                this.availableFluids.push(conf.fluid);
            });
        }
        this.availableFluids = H.arrayUnique(this.availableFluids);
    }

    getAgentPrice(agentKey: string): number {
        const priceMap = {};
        priceMap[K.EnergyAgents.GAZ_COMBUSTIBLE] = 100;
        priceMap[K.EnergyAgents.HEATING_OIL] = 100;
        priceMap[K.EnergyAgents.ELECTRICITY] = 100;
        priceMap[K.EnergyAgents.WOOD] = 0.1;
        priceMap[K.EnergyAgents.CAD] = 100;
        return priceMap[agentKey];
    }

    openBmensAttachment(row: BmensItemRow) {
        const fileName = row['file'];
        if (fileName.includes('.')) {
            const u = "https://api.optimigration.ch/web/bmens/" + fileName;
            const pdfSource = "https://api.optimigration.ch/endpoint/cors?f=" + H.utf8_to_b64(u);

            this.myapp.globalDocumentViewer.next({pdfSource, metas: null, title: row.label});
        } else {
            this.myapp.storage.ref("attachments/" + fileName).getDownloadURL().subscribe(u => {
                console.log(u);
                const pdfSource = "https://api.optimigration.ch/endpoint/cors?f=" + H.utf8_to_b64(u);
                this.myapp.storage.ref("attachments/" + fileName)
                    .getMetadata()
                    .subscribe(meta => {
                        this.myapp.globalDocumentViewer.next({pdfSource, metas: meta, title: row.label});
                    });
            });
        }
        console.log(row);
    }

    openAttachment(path: string) {
        this.myapp.storage.ref(path).getDownloadURL().subscribe(u => {
            console.log(u);
            const pdfSource = "https://api.optimigration.ch/endpoint/cors?f=" + H.utf8_to_b64(u);
            this.myapp.storage.ref(path)
                .getMetadata()
                .subscribe(meta => {
                    this.myapp.globalDocumentViewer.next({pdfSource, metas: meta, title: meta.title});
                });
        });
    }

    // lifecycle helpers
    selectSite(site: ClientSite) {
        // console.log("Site::-------------GET----:::::selectSite====", site);
        this.clientSite = site;
        this.uid = this.clientSite.uid;
        localStorage.setItem('selectedDomainUID', this.clientSite.uid);
        localStorage.setItem('selectedDomainRef', this.clientSite.ref);
        localStorage.setItem('selectedDomain', JSON.stringify(this.clientSite));
    }

    // SITE RESELECT RESET FIELD
    resetFieldsAfterSiteSelect() {
        this.selectedPdm = null;
        this.selectedAction = null;
        this.dataSummaryMap = new Map<string, PdmDataSummary>();
        this.availableFluids = [];
        this.availableYearsInRows = [];
        this.varsExplFromRegs = {};
        this.selectedAction = null;
        this.actions = [];
        this.chapters = [];
        //this.myapp.appInitStatus.next(2);
    }

    public reload() {
        this.resetFieldsAfterSiteSelect();
        this.myapp.appInitStatus.next(2);
    }

    // PDMs related
    //-------
    // Warning, this method does many steps in specific order
    loadSitePdms(observer: Observer<any> = null) {
        this.tried_loading_pdms = true;
        this.api.getPdms(this.uid).subscribe(resp => {
            this.pdms = [];
            resp.body.forEach(rawPdm => {
                const _pdm = new Pdm(rawPdm);
                this.pdms.push(_pdm);
                this.availableFluids.push(_pdm.fluid);
            });
            this.availableFluids = this.availableFluids.filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).sort();
            this.siteSpecificEventTriggerer.next(SiteService.PDMS_LOADED);
            if (observer) {
                observer.next(null);
                observer.complete();
            }
        });
    }

    selectYear(y: number) {
        console.log("Select year", y);
        this.year = y;
        // populate locally pdmYearlyConfig for selected year to retrieve cassure config and model chosenVars
        this.selectedConfigForPdmAndYear = this.selectedPdm.getYearlyConfigForYear(y);
        // get refYear from yearlyConfig
        this.refYear = this.selectedConfigForPdmAndYear.year_ref;
        if (this.refYear) {
            // get yearlyConfig for refYear to retrive the model
            this.selectedConfigForPdmAndREFYear = this.selectedPdm.getYearlyConfigForYear(this.refYear);
        }
        // announce to the app that yearly config are loaded
        this.siteSpecificEventTriggerer.next(SiteService.PDMS_YEARLY_CONFIG_SELECTED);
        // trigger plot data calculation according to selected year and yearly config over dataRows
        this.scanReleveData();
    }

    // generate PdmYearlyCalcedData: calc data for plots
    scanReleveData() {
        let cumulVe = 0;
        let cumulDays = 0;
        this.selectedPdmYearlyPlotDataMap = new Map<number, PdmYearlyCalcedData>();
        this.selectedPdm.relevesCache.forEach((rowData, date) => {
            const yearlyConfigForYear = this.selectedPdm.getYearlyConfigForYear(rowData.releve_year);
            if (rowData.releve_num === 1) {
                cumulDays = 0;
            }
            cumulDays += rowData.days;
            if (rowData.releve_num === 12) {
                cumulVe = 0;
            } else {
                cumulVe += rowData.ve;
            }
            rowData.days_cumul = cumulDays;
            const pdmYearlyPlotData = new PdmYearlyCalcedData(rowData, yearlyConfigForYear, cumulVe, cumulDays);
            // console.log('rowData.data_key', rowData.data_key);
            this.selectedPdmYearlyPlotDataMap.set(rowData.data_key, pdmYearlyPlotData);
        });
        // console.log('this.selectedPdmYearlyPlotDataMap', this.selectedPdmYearlyPlotDataMap);
        this.siteSpecificEventTriggerer.next(SiteService.PDMS_YEARLY_PLOT_DATA_CALCED);
    }

    updateSignatureConfig(newSignature: PdmSignature) {
        // updates pdm.yearly_config and triggers yearlyCalc
        this.selectedConfigForPdmAndYear.cassure_final = newSignature.cassure;
        this.selectedConfigForPdmAndYear.cassure_manual = newSignature.manual;
        this.selectedConfigForPdmAndYear.cassure_sd = newSignature.sd;
        this.selectedConfigForPdmAndYear.cassure_type = newSignature.type;
        this.selectedConfigForPdmAndYear.cassure_flat = newSignature.flat;
        this.saveYearlyConfigToPdm();
    }

    updateModel(chosenVars: string[], model: number[]) {
        this.selectedConfigForPdmAndYear.vars_used = chosenVars;
        this.selectedConfigForPdmAndYear.model = model;
        this.saveYearlyConfigToPdm();
    }

    updateRefYear(refYear: number) {
        this.selectedConfigForPdmAndYear.year_ref = refYear;
        this.saveYearlyConfigToPdm();
    }

    saveYearlyConfigToPdm() {
        // updates pdm.yearly_config locally, remotely and trigger yearlyCalc and PDMS_YEARLY_CONFIG_CHANGED
        this.selectedPdm.yearly_config_map.set(this.year, this.selectedConfigForPdmAndYear);
        this.selectedPdm.yearly_config[this.year.toString()] = this.selectedConfigForPdmAndYear;
        this.api.savePdm(this.selectedPdm).subscribe(resp => {
            /// TODO: save only selected field not all PDM
            console.log('savePdm Resp Status, Data', resp.status, resp.body);
            // updating selectedPdm and pdm in this.pdms
            const releveCacheCopy = this.selectedPdm.relevesCache;
            const pdmMap = new Map<string, Pdm>();
            this.pdms.forEach(pdm => pdmMap.set(pdm.uid, pdm));
            this.selectedPdm = new Pdm(resp.body);
            this.selectedPdm.relevesCache = releveCacheCopy;
            pdmMap.set(this.selectedPdm.uid, this.selectedPdm);
            this.pdms = Array.from(pdmMap.values());
            // trigger events
            this.selectYear(this.year); // refresh
        });
    }

    populatePdmCache(force = true, withMeta = true, debugCaller = "Empty") {
        // reloads pdm_rows after pdm select or row calc or edit
        if (!this.selectedPdm.relevesCache || force) {
            if (!this.selectedPdm.relevesCache) this.selectedPdm.relevesCache = new Map<string, PdmDataRow>();
            this.availableYearsInRows = [];

            this.api.getPdmData(this.selectedPdm.uid, withMeta, this.selectedPdm.is_vpdm).subscribe(resp => {
                let latestYear = 0;
                if (resp && resp.body && resp.body.length > 0) {
                    const serverData = resp.body;
                    serverData.forEach((rowReleveFromServer: any) => {
                        const rowData = new PdmDataRow(rowReleveFromServer, [], withMeta);
                        this.selectedPdm.relevesCache.set(rowData.date, rowData);
                        this.availableYearsInRows.push(rowData.releve_year);
                        latestYear = rowData.releve_year;
                    });
                    this.availableYearsInRows = H.arrayUnique(this.availableYearsInRows).sort();
                    this.siteSpecificEventTriggerer.next(SiteService.PDMS_DATA_POPULATED);
                    this.myapp.showMessage('Données du PDM chargées: ' + this.year);
                }
                this.selectYear(latestYear); // use selectYear as common hook to restart chaned procedures
                // this.scanReleveData();
            }, error => {
                console.log("getRelevesFromServer:init Map", error);
            });
        }
    }

    loadPdmEpLinkData(observer: Observer<any> = null) {
        if (this.clientSite && this.clientSite.ep_db_name) {
            this.api.getRegLinks(this.clientSite.ep_db_name).subscribe(res => {
                this.selectedSiteEpDataBase = res.body;
                if (observer && res.status === 1) {
                    observer.next(null);
                    observer.complete();
                } else {
                    console.error('loadPdmEpLinkData', this.clientSite.ep_db_name, res);
                    // this.myapp.showError("EP LINK LOADED");
                }
            });
        } else {
            if (observer) {
                observer.next(null);
                observer.complete();
            }
        }
    }

    /*
        selectReportYearAndInitPdmYearlyConfig(year: number) {
            console.info("Site:selectReportYearAndInitPdmYearlyConfig()", year, this.sitePdmsYearlyConfigMap);
            this.year = year;
            this.selectedPdmConfigForYear = this.sitePdmsYearlyConfigMap.get(this.selectedPdm.uid + "_" + this.year);
            this.refYear = null;
            console.info("selectReportYear", year);

            if (this.selectedPdmConfigForYear && this.selectedPdmConfigForYear.year_ref) {
                this.refYear = this.selectedPdmConfigForYear.year_ref;
                // int config from refYear (source of our models for quality_ref_mens, and cassure finder
                this.selectedPdmConfigForRefYear = this.sitePdmsYearlyConfigMap.get(this.selectedPdm.uid + "_" + this.refYear);
                console.info("selectReportYear: EXISTS", this.selectedPdmConfigForYear, this.selectedPdmConfigForRefYear);
                if (!this.selectedPdmConfigForRefYear) {
                    console.info("selectedPdmConfigForRefYear: Creating");
                    this.saveSelectedPdmConfig('model', {}, true);
                    return;
                }


                // console.log("this.varsExplFromRegs", this.varsExplFromRegs);
                this.toastr.success('Configurations chargées: ', 'Evenement!');
                // this.siteSpecificEventTriggerer.next(SiteService.PDMS_DATA_POPULATED);
            }
        }

        saveSelectedPdmConfig(wat: string, data: any, andReload = false) {
            let configYearToUpdate = this.year;
            if (wat === 'model') configYearToUpdate = this.refYear;//wat === "signature" ||
            // if (data['key'] === 'SIGNATURE_FINDER') configYearToUpdate = this.refYear;
            this.api.setPdmYearConfig(this.selectedPdm, this.clientSite, configYearToUpdate, wat, data).subscribe(resp => {
                console.log("saveSignature:", resp, data);
                this.toastr.success('Configuration sauvegardée: ' + wat, 'Evenement!');
                if (andReload) {
                    this.loadSitePdmsYearlyConfigs();
                }
            });
        }
    */
}

export class ActionStat {
    total: number = 0;
    refused: number = 0;
    completed: number = 0;
    pending: number = 0;
    urgent: number = 0;
}
