import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";

import {SiteService} from "../../shared/services/site.service";
import {
    Plot
} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ModelManager} from "../../shared/helpers/ModelManager";
import {H} from "../../shared/helpers/H";
import {K} from "../../shared/models/K";
import * as Highcharts from 'highcharts';
import {Optimise} from "../../shared/helpers/Optimise";
import {DashStyleValue} from "highcharts";

@Component({
    selector: 'statmens-cumul',
    template: `
        <div fxLayout="row">
            <div fxFlex="1050px">
                <mat-card class="m-4 p-8">
                    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
                </mat-card>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensCumulComponent implements OnInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    modelManager: ModelManager;
    chosenVars = [];
    consUnit = 'KWh';

    plot: Plot;
    previousSerie = [];
    historyCount = 5;
    seriesData = [];
    public rawDataFromManager: any = null;
    private colors = ['#ff0000', '#00ff00', '#00ccff', '#cc99ff', '#ffff00'];

    constructor(public myapp: AppService, public site: SiteService, private snackBar: MatSnackBar, public sanitizer: DomSanitizer) {
        this.site.siteSpecificEventTriggerer.subscribe(eventName => {

            if (eventName === SiteService.PDMS_YEARLY_PLOT_DATA_CALCED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_CHANGED) {
                this.bootstrap();
            }
            if (eventName === SiteService.PDMS_YEARLY_CONFIG_SELECTED) {
                this.bootstrap();
            }
        });
    }

    ngOnInit() {

    }

    bootstrap() {
        this.chosenVars = this.site.selectedConfigForPdmAndYear.vars_used;
        this.modelManager = new ModelManager(
            this.site.selectedPdmYearlyPlotDataMap,
            this.site.selectedConfigForPdmAndYear,
            this.site.selectedConfigForPdmAndREFYear,
        );
        this.plot = new Plot(this.site.year, 5, 'line', Plot.SECTION_BASIC, 'CONS', true, false,
            "VE1_D".toLowerCase(), this.site.selectedMonthIndex);
        // console.log("StatmensPrevisions: ",
        //     this.site.year, this.site.refYear,
        //     this.modelManager.consVector, this.modelManager.derniere);
        this.plotChart();
        console.log(this.seriesData);
        this.plotcumul();
    }

    varLabel(key) {
        if (this.modelManager
            && this.modelManager.currYearCalcedRows
            && this.modelManager.currYearCalcedRows.length
            && this.modelManager.currYearCalcedRows[0].var_labels[key])
            return this.modelManager.currYearCalcedRows[0].var_labels[key];
        else return key;
    }

    plotcumul() {
        const categories = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc', ''];
        this.modelManager.calcDerniere();
        if (!this.modelManager.derniere) {
            console.error("Plot derniere, derniere undefined");
            return;
        }
        const moisIndexes = [];
        let maxCons = 0;
        let accRealCons = 0;
        let accDerniere = 0;
        const derniereData = this.modelManager.derniere.map((pty, i) => {
            accDerniere += pty;
            moisIndexes.push(i);
            return {x: this.modelManager.daysCumul[i], y: accDerniere / 1000, name: i};
        });
        const realConsData = this.modelManager.consVector.map((pty, i) => {
            const delta = (pty - derniereData[i]['y']) / 1000;
            const deltaPercent = 100 * (delta / derniereData[i]['y']);
            accRealCons += pty;
            if (accRealCons > maxCons) maxCons = accRealCons;
            return {x: this.modelManager.daysCumul[i], y: accRealCons / 1000, name: delta, delta: deltaPercent};
        });

        derniereData.unshift({x: 0, y: 0, name: 0});
        realConsData.unshift({x: 0, y: 0, name: 0, delta: 0});
        moisIndexes.push('');
        const series = this.getSeries();
        series.push({
            // virtual serie to make second axis shoing month names show ! Hack
            showInLegend: false,
            xAxis: 1,
            color: 'transparent',
            name: "Mois",
            type: "line",
            data: moisIndexes || []
        });
        this.chartOptions = {
            chart: {
                displayErrors: true,
                plotBackgroundColor: '#ffffff',
                plotShadow: false,
                borderColor: '#000000',
                borderWidth: 0,
                plotBorderWidth: K.lineWidthBase,
                reflow: false,
                width: 1050,
                height: 500,
                marginTop: 40,
                marginRight: 120,
                spacingRight:10,
                style: {
                    fontFamily: "Roboto",
                    fontSize: "14px"
                }
            },
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: undefined
            },
            lang: {
                thousandsSep: "'",
                numericSymbols: null,
            },
            xAxis: [
                {
                    lineWidth: 2,
                    lineColor: '#000000',
                    gridLineColor: "#000000",
                    gridLineWidth: 0.2,
                    tickInterval: 30,
                    crosshair: true,
                    min: 0,
                    max: 390,
                    startOnTick: true,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: '#000000',
                            fontSize: '14px',
                        }
                    },
                    title: {
                        text: "Jours cumulés"
                    }
                },
                {
                    labels: {
                        style: {
                            color: '#000000',
                            fontSize: '11px'
                        }
                    },
                    type: "category",
                    opposite: true,
                    lineWidth: 0,
                    offset: -45,
                    categories: categories,
                    crosshair: true
                }
            ],
            yAxis: {
                lineWidth: 2,
                lineColor: '#000000',
            },
            accessibility: {
                enabled: false
            },
            legend: {
                "align": "right",
                "borderWidth": 1,
                "itemMarginBottom": 0,
                itemMarginTop: 0,
                itemStyle: {
                    fontSize: "12px",
                    fontWeight: "normal",
                    lineHeight: "12px"
                },
                layout: "vertical",
                margin: 20,
                padding: 5,
                squareSymbol: true,
                symbolRadius: 1,
                symbolWidth: 40,
                verticalAlign: "middle",
                width: 100,
            },
            series: series
        };
    }

    getSeries() {
        const series = [];
        for (let i = 0; i < this.historyCount; i++) {
            let assetIndex = this.historyCount - i - 1;
            let year = this.plot.yearReport - this.historyCount + i + 1;
            console.log("assetIndex", assetIndex, year);
            const serie = {
                color: assetIndex === 4 ? '#000000' : this.colors[assetIndex],
                marker: {
                    fillColor: this.colors[assetIndex],
                    lineColor: '#000000',
                    width: 50,
                    enabled: true,
                    lineWidth: assetIndex === 4 ? 1 : 0,
                    symbol: K.markers[assetIndex],
                    radius: 6
                },
                dashStyle: K.lineType[assetIndex] as DashStyleValue,
                // color: K.chartColors[i],
                lineWidth: 2,
                name: year,
                type: "line", tooltip: {
                    headerFormat: "{series.name}",
                    pointFormat: '<br>{point.category}: <b> {point.y:.2f}</b>',
                },
                data: this.seriesData[i] || []
            };
            if (year === this.plot.yearReport) {
                serie['dataLabels'] = {
                    useHTML: true,
                    enabled: true,
                    inside: true,
                    x: 5,
                    y: 5,
                    align: "left",
                    position: "right",
                    rotation: 30,
                    borderWidth: 1,
                    borderColor: 'red',
                    padding: 20,
                    allowOverlap: true,
                    style: {fontWeight: "regular"},
                    format: "{point.name:,.0f}  " + this.consUnit + "; {point.delta: .1f}% ",
                };
            }
            series.push(serie);
        }
        return series;
    }

    plotChart() {
        this.seriesData = [];
        for (let y = 0; y < this.plot.yearCountHistory; y++) {
            let data = [];
            let data2 = [];
            const yearOfCurrentSerie = this.site.year + y + 1 - this.historyCount;
            this.rawDataFromManager = this.site.selectedPdm.getDataForYear(yearOfCurrentSerie, this.plot);
            let cum = 0;
            data = this.rawDataFromManager.map((item, i) => {
                cum += item.val;
                return this.getPointsForCumul(item, i);
            });

            data.unshift({x: 0, y: 0, index: 0});
            if (yearOfCurrentSerie === this.site.refYear) {
                this.previousSerie = data;
            }

            if (this.site.selectedPdm.getIsHaveMultipleSeries() && this.plot.isMain()) {
                data2 = this.rawDataFromManager.map((item, i) => {
                    return this.getPointsForCumul(item, i, 2);
                });
            }
            this.seriesData.push(data);
        }
    }

    getPointsForCumul(item, i, serieNum = 1) {
        let deltaPrev = 0;
        let percent = 0;
        let val = item.val;
        if (serieNum === 2) val = item.val2;

        if (this.previousSerie[i] && this.previousSerie[i].y)
            deltaPrev = Number(val - this.previousSerie[i].y) || 0;

        if (deltaPrev !== 0 && this.previousSerie[i].y !== 0) {
            percent = 100 * (deltaPrev / this.previousSerie[i].y);
        }

        const retVal = {x: item.days_cumul, y: val, date: item.date, custom: percent, name: deltaPrev};
        return retVal;
    }

    ngOnChanges(changes: SimpleChanges) {
    }

}
