import {Component, OnInit} from '@angular/core';
import {ClientSite, SiteAccess, User} from "../../../../shared/models/models";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {AppConfirmService} from "../../../../shared/services/app-confirm/app-confirm.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {deleteField,FieldValue} from 'firebase/firestore';
import {H} from "../../../../shared/helpers/H";
import {egretAnimations} from "../../../../shared/animations/egret-animations";
import firebase from "firebase/app";

@Component({
    selector: 'app-users',
    template: `
        <div fxLayout="row" fxLayoutAlign="space-between none">
            <!-- left list -->
            <mat-card fxFlex="460px" class="default full-width">
                <mat-card-title class="p-8 list-user-head">Utilisateurs
                    <span *ngIf="usersToDisplay" class="text-muted">({{ usersToDisplay.length }})</span>
                    <a *ngIf="myapp.isAdmin"
                       target="_blank" href="https://api.optimigration.ch/endpoint/users-export-xls">Exporter xls</a>
                </mat-card-title>
                <mat-card-content fxLayout="column" fxLayoutAlign="start stretch"
                                  class="flex-container p-0">
                    <div class="p-8">
                        <div fxLayout="row">
                            <button fxFlex mat-raised-button color="primary" class="btn-xs-25 mr-4"
                                    [ngClass]="{active:selectedRole==='ADMIN'}"
                                    (click)="selectedRole='ADMIN';filterUsers();">
                                Administrateur
                            </button>
                            <button fxFlex mat-raised-button color="primary" class="btn-xs-25 mr-4"
                                    [ngClass]="{active:selectedRole==='SUPERUSER'}"
                                    (click)="selectedRole='SUPERUSER';filterUsers();">
                                Super utilisateur
                            </button>
                            <button fxFlex mat-raised-button color="primary" class="btn-xs-25 mr-4"
                                    [ngClass]="{active:selectedRole==='AUTHENTICATED'}"
                                    (click)="selectedRole='AUTHENTICATED';filterUsers();">
                                Utilisateur
                            </button>
                        </div>
                    </div>
                    <div class="p-8">
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="buttons-bar">
                            <mat-form-field fxFlex>
                                <input matInput [(ngModel)]="filterKey" placeholder="Filtre" (keyup)="filterUsers()">
                            </mat-form-field>
                            <button mat-raised-button color="primary" fxFlex="30" class="btn-xs-25 mr-4 pl-4 text-left"
                                    [ngClass]="{active:sortBy==='alpha'}" (click)="sortUsers('alpha')"> Tri par
                                <span *ngIf="!sortDir">Prénom </span>
                                <span *ngIf="sortDir">Nom</span>
                                <ng-container *ngIf="sortBy==='alpha'">
                                    <mat-icon inline *ngIf="!sortDir">arrow_drop_up</mat-icon>
                                    <mat-icon inline *ngIf="sortDir===1">arrow_drop_up</mat-icon>
                                    <mat-icon inline *ngIf="sortDir===-1">arrow_drop_down</mat-icon>
                                </ng-container>
                            </button>
                            <button mat-raised-button color="primary" fxFlex="30" class="btn-xs-25 mr-4 pl-4 text-left"
                                    [ngClass]="{active:sortBy==='siteCount'}" (click)="sortUsers('siteCount')"> Nb sites
                                <ng-container *ngIf="sortBy==='siteCount'">
                                    <mat-icon inline *ngIf="sortDir===1">arrow_drop_up</mat-icon>
                                    <mat-icon inline *ngIf="sortDir===-1">arrow_drop_down</mat-icon>
                                </ng-container>
                            </button>
                        </div>
                    </div>
                    <div class="scroller">
                        <mat-list class="full-widt h mb-32 compact-list" fxLayout="column" fxLayoutAlign="start none">
                            <ng-container *ngFor="let user of usersToDisplay">
                                <mat-list-item class="full-wid th p-8"
                                               fxLayout="row" fxLayoutAlign="space-between center"
                                               [ngClass]="{active:selectedUser&&selectedUser.uid===user.uid}"
                                               (click)="selectUser(user)">

                                    <h6 class="m-0 ml-8" fxFlex>
                                        <span [innerHTML]="user.fullName(sortBy==='alpha'&&sortDir!==0,true)"> </span>
                                        <br>
                                        <span class="text-muted"> <b>{{ user.group }}</b> | {{ user.office }} </span>
                                    </h6>
                                    <mat-icon fxFlex="30px" inline="true"
                                              title="Mentionner le groupe SVP"
                                              *ngIf="!user.group" class="icon-vpdm color-red">warning
                                    </mat-icon>
                                    <mat-basic-chip *ngIf="user.group&&user.group==='CANTON'">CT</mat-basic-chip>
                                    <mat-icon fxFlex="30px" inline="true"
                                              *ngIf="user.invited" class="icon-vpdm">hourglass_full
                                    </mat-icon>
                                    <span fxFlex="20px" *ngIf="user.site_access" class="text-right font-weight-bold color-blue">
                                        {{ user.site_access.size }}
                                    </span>

                                </mat-list-item>
                            </ng-container>
                        </mat-list>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- main body -->
            <mat-card class="p-0" fxFlex="1000px">
                <mat-card-title>
                    <h1 class="p-16 pb-0" *ngIf="selectedUser">Edition de {{ selectedUser.fullName() }}</h1>
                </mat-card-title>
                <mat-card-content class="p-0 flex-container">
                    <div class="p-8 flex-container" fxLayout="row">
                        <!-- main form -->
                        <div fxFlex="40">
                            <form [formGroup]="formPdm" *ngIf="formPdm&&selectedUser" class="p-8">
                                <h2 class="p-8 pb-0">Informations de base</h2>
                                <div fxLayout="row">
                                    <div class="p-4">
                                        <div class="bloc-user-email">E-mail: <b class="color-blue">{{ selectedUser.email }}</b></div>
                                        <div class="pb-8" *ngIf="selectedUser.invited">Invitation envoyée: <b>{{ selectedUser._momInvited|relativeTime }}</b>
                                        </div>
                                        <mat-divider class="pb-16"></mat-divider>
                                        <mat-form-field class="full-width">
                                            <mat-select formControlName="role"
                                                        [disabled]="!myapp.isAdmin"
                                                        placeholder="Rôle" [value]="selectedUser.role" (valueChange)="updateRole($event)">
                                                <mat-option [value]="r.key" *ngFor="let r of myapp.k.Roles|keyvalue">
                                                    {{ myapp.k.RoleDisplayName[r.value] }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <mat-select formControlName="group" placeholder="Selectionner le groupe"
                                                        [disabled]="!myapp.isAdminOrSuperUser" [value]="selectedUser.group"
                                                        (valueChange)="updateGroup($event)">
                                                <mat-option [value]="'CLIENT'"> CLIENT</mat-option>
                                                <mat-option [value]="'ENERPLAN'"> ENERPLAN</mat-option>
                                                <mat-option [value]="'CANTON'"> CANTON</mat-option>
                                                <mat-option [value]="'AUTRE'"> AUTRE</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field class="full-width">
                                            <mat-select formControlName="gender" placeholder="Gentilé" [value]="selectedUser.gender">
                                                <mat-option strEnh enh="ucfirst" value="Monsieur"> Monsieur</mat-option>
                                                <mat-option strEnh enh="ucfirst" value="Madame"> Madame</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field *ngIf="selectedUser.inviteToken"
                                                        class="full-width">
                                            <input matInput formControlName="email"
                                                   placeholder="E-mail">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="first_name" placeholder="Prénom">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="last_name" placeholder="Nom">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="office" placeholder="Poste">
                                        </mat-form-field>

                                        <h2 class="mt-16">Données supplémentaires</h2>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="company" placeholder="Entreprise">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="npa_loc" placeholder="NPA - Localité">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="address" placeholder="Rue, N°">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="tel_fixed" placeholder="Tél. fixe">
                                        </mat-form-field>
                                        <mat-form-field class="full-width">
                                            <input matInput formControlName="tel_mobile" placeholder="Tél. mobile">
                                        </mat-form-field>
                                        <div fxLayout="row" class="pt-16">
                                            <button mat-raised-button color="accent" class="btn-xs-25 mr-4" (click)="clone()">Cloner</button>
                                            <button mat-raised-button color="accent" class="btn-xs-25 mr-4"
                                                    *ngIf="selectedUser.inviteToken"
                                                    (click)="delUser()">Del
                                            </button>
                                            <span fxFlex></span>
                                            <button mat-raised-button color="primary"
                                                    class="btn-xs-25"
                                                    [disabled]="!formPdm.valid"
                                                    (click)="saveUser()">Sauvegarder
                                            </button>
                                        </div>
                                        <div fxLayout="row" class="pt-16" *ngIf="selectedUser.invited">
                                            <button mat-raised-button color="primary"
                                                    class="btn-xs-25"
                                                    [disabled]="!formPdm.valid"
                                                    (click)="inviteUser()">Envoyer email invitation
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- roles form -->
                        <div fxFlex="60">
                            <ng-container *ngIf="selectedUser">
                                <h2 class="p-8 pb-0">Droits d'accès aux sites</h2>
                                <div class="p-8">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-form-field fxFlex>
                                            <input matInput [(ngModel)]="filterSiteKey" placeholder="Ajouter un accès" (keyup)="filterSites()">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="scroller">
                                    <mat-card class="p-4 m-8" *ngFor="let siteAccess of selectedUser.sites">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <div fxFlex fxLayout="column" class="mr-8">
                                                <div class="color-blue font-weight-bold">
                                                    {{ siteAccess.site_name }}
                                                </div>
                                                <div>
                                                    <mat-select [value]="siteAccess.responsability"
                                                                (valueChange)="updateUserAccess(siteAccess.uid_site,$event)">
                                                        <mat-option [value]="roleKV.key"
                                                                    *ngFor="let roleKV of myapp.k.domainResponsability|keyvalue">
                                                            {{ getResponsabilityLabel(roleKV.key) }}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <button mat-icon-button>
                                                <mat-icon class="cursor-pointer" (click)="selectedSiteAccess=siteAccess">edit</mat-icon>
                                            </button>
                                            <div fxFlex="65px">
                                                <button mat-raised-button class="btn-xs-25"
                                                        (click)="revoquer(siteAccess.uid_site)">
                                                    Révoquer
                                                </button>
                                            </div>
                                        </div>

                                        <div class="user-rights" *ngIf="userRights[siteAccess.uid_site]&&selectedSiteAccess===siteAccess">
                                            <div class="form-section" fxLayout="column">
                                                <div *ngFor="let p of accessPagesNames">
                                                    <mat-checkbox #main class="mr-4 checkbox"
                                                                  (change)="clearRightsFor($event.checked,siteAccess,p)"
                                                                  [(ngModel)]="userRights[siteAccess.uid_site][p+'@VIEW']">
                                                        Accès: <span>[ {{ p }} ]</span>
                                                    </mat-checkbox>
                                                    <div class="ml-16" *ngIf="main.checked" [@slideInOut]>
                                                        <span>Droits</span>
                                                        <mat-checkbox class="m-4 actions-checkbox"
                                                                      [(ngModel)]="userRights[siteAccess.uid_site][p+'@'+action]"
                                                                      *ngFor="let action of myapp.k.AccessPages[p]">
                                                            {{ action }}
                                                        </mat-checkbox>
                                                    </div>
                                                    <mat-divider></mat-divider>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

            <div fxFlex="350px">
                <div fxLayout="row" class="  list-item-site" (click)="addAccess(siteObj)" *ngFor="let siteObj of sitesToDisplay">
                    <div class="w-40 h-40 mr-16  ">
                        <ngx-avatar [name]="siteObj.ref" [size]="40"></ngx-avatar>
                    </div>
                    <div class="d-flex flex-column justify-content-center flex-grow-1 font-weight-bold">
                        {{ siteObj.name }}
                    </div>
                </div>

            </div>
            <div fxFlex>
                <pre style="font-size: 12px;overflow: hidden">{{ selectedUser|json }}</pre>
            </div>
        </div>
    `,
    styleUrls: ['./users.component.scss'], animations: egretAnimations
})
export class UsersComponent implements OnInit {
    formPdm: FormGroup = null;

    selectedSiteAccess: SiteAccess;
    sitesToDisplay: ClientSite[];
    clientsSitesMap: Map<string, ClientSite> = new Map<string, ClientSite>();

    usersToDisplay: User[];
    usersMap: Map<string, User> = new Map<string, User>();
    selectedUser: User;

    userRights = {};
    selectedRole: string;
    filterSiteKey = '';
    filterKey = '';
    sortBy = "siteCount";
    sortDir = 1;

    constructor(
        private fb: FormBuilder,
        public rdb: AngularFireDatabase,
        public myapp: AppService,
        public site: SiteService,
        public confirmService: AppConfirmService,
    ) {

    }

    get accessPagesNames(): string[] {
        return Object.keys(this.myapp.k.AccessPages)
    }

    ngOnInit(): void {
        this.myapp.storeCurrentRoute();

        this.formPdm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            gender: ["", []],
            first_name: ['', [Validators.required]],
            last_name: ["", [Validators.required]],
            office: ["", [Validators.required]],
            role: ["", [Validators.required]],
            group: ["", [Validators.required]],
            // ---> following will be stored in infos
            npa_loc: ["", []],
            address: ["", []],
            company: ["", []],
            tel_fixed: ["", []],
            tel_mobile: ["", []]
        });
        this.loadSites();
    }

    selectUser(user: User) {
        this.userRights = {};
        if (user.site_access)
            user.site_access.forEach((v, k) => {
                this.userRights[k] = {};
                if (v.rights)
                    v.rights.forEach(vv => this.userRights[k][vv] = true);
            });
        console.log("SelectUser", user, this.userRights);
        this.selectedUser = user;
        if (!this.formPdm || !this.selectedUser) return;
        this.formPdm.controls['email'].patchValue(this.selectedUser.email);
        this.formPdm.controls['first_name'].patchValue(this.selectedUser.first_name);
        this.formPdm.controls['last_name'].patchValue(this.selectedUser.last_name);
        this.formPdm.controls['gender'].patchValue(this.selectedUser.gender);
        this.formPdm.controls['office'].patchValue(this.selectedUser.office);
        this.formPdm.controls['role'].patchValue(this.selectedUser.role);
        this.formPdm.controls['group'].patchValue(this.selectedUser.group);
        if (this.selectedUser.infos && this.selectedUser.infos) {
            this.formPdm.controls['npa_loc'].patchValue(this.selectedUser.infos.npa_loc);
            this.formPdm.controls['address'].patchValue(this.selectedUser.infos.address);
            this.formPdm.controls['company'].patchValue(this.selectedUser.infos.company);
            this.formPdm.controls['tel_fixed'].patchValue(this.selectedUser.infos.tel_fixed);
            this.formPdm.controls['tel_mobile'].patchValue(this.selectedUser.infos.tel_mobile);
        }
    }

    getResponsabilityLabel(stored) {
        const respValue = this.myapp.k.domainResponsability[stored];
        return this.myapp.k.displayDomainResponsability[respValue];
    }

    loadUsers() {
        this.myapp.db.collection('users').get().subscribe(resp => {
            resp.forEach(u => {
                const uObj = new User(u.data());
                this.usersMap.set(uObj.uid, uObj);
            });
            this.filterUsers();
        });
    }

    loadSites() {
        this.site.api.listSites('').subscribe(res => {
            // this.list_statmens = JSON.parse(res);
            // console.log('selectDomainEvt', res);
            this.clientsSitesMap = new Map<string, ClientSite>();
            res.body.forEach(rawSite => {
                const clSite = new ClientSite(rawSite);
                this.clientsSitesMap.set(clSite.uid, clSite);
            });
            this.loadUsers();
        });
    }

    filterUsers() {
        this.usersToDisplay = Array.from(this.usersMap.values()).filter(u => {
            return u.role === this.selectedRole && (u.fullName().toLowerCase().includes(this.filterKey.trim().toLowerCase())
                || u.email.toLowerCase().includes(this.filterKey.trim().toLowerCase())
            );
        });
        //this.sortUsers('alpha');
    }

    filterSites() {
        if (this.filterSiteKey.length < 3) return;
        this.sitesToDisplay = Array.from(this.clientsSitesMap.values()).filter(u => {
            return u.strForFiltering.includes(this.filterSiteKey.trim().toLowerCase())
        });
        this.sitesToDisplay = this.sitesToDisplay.sort((s1, s2) => {
            return s1.name.localeCompare(s2.name);
        })
    }

    sortUsers(by: string) {//alpha siteCount
        if (by === this.sortBy) {
            this.sortDir++;
            if (this.sortDir === 2) this.sortDir = -1;
        }
        this.sortBy = by;
        if (this.sortBy === "alpha") {
            this.usersToDisplay = this.usersToDisplay.sort((u1, u2) => {
                if (this.sortDir !== 0)
                    return u1.last_name.toLowerCase().localeCompare(u2.last_name.toLowerCase()) * this.sortDir;
                else
                    return u1.first_name.toLowerCase().localeCompare(u2.first_name.toLowerCase());
            });
        }
        if (this.sortBy === "siteCount") {
            this.usersToDisplay = this.usersToDisplay.sort((u1, u2) => {
                return (u1.site_access.size - u2.site_access.size) * this.sortDir;
            });
        }
    }

    clone() {
        const newUser = {...this.selectedUser} as any;
        console.log('Clone()', newUser, H.removeNullsFromObject(newUser));
        //delete newUser.site_access;
        delete newUser._momInvited;

        const rights = Object.fromEntries(newUser["site_access"].entries());
        Object.keys(rights).forEach(domain => {
            console.log('forEach()', rights[domain]);
            rights[domain] = {...rights[domain]}
        });
        newUser["site_access"] = rights;
        newUser["uid"] = H.randomStr(30);
        newUser["uid_bdd"] = newUser["uid"];
        newUser["first_name"] = "NC";
        newUser["last_name"] = 'NC';
        newUser["email"] = 'NC';
        newUser["invited"] = true;
        newUser["inviteToken"] = H.randomStr(10).toLowerCase();
        newUser["ts_invited"] = H.unixTs(true)
        newUser["ts"] = H.unixTs(true)
        this.myapp.db.collection('users')
            .doc(newUser.uid)
            .set(newUser).then(resp => {
            console.log("newUser", resp);
            this.refreshUser(newUser.uid);
        });
    }

    delUser() {
        this.myapp.db.collection('users').doc(this.selectedUser.uid).delete().then(resp => {
            this.usersMap.delete(this.selectedUser.uid);
            this.filterUsers();
            this.selectedUser = null;
            this.userRights = null;
            this.myapp.showError("Utilisateur supprimé !")
        })
    }

    /*

     */
    clearRightsFor(isChecked: boolean, siteAccess: SiteAccess, page: string) {

        if (!isChecked && this.userRights[siteAccess.uid_site]) {
            Object.keys(this.userRights[siteAccess.uid_site]).forEach(pageAccessTag => {
                if (pageAccessTag.includes(page))
                    this.userRights[siteAccess.uid_site][pageAccessTag] = false;
            });
        }
        // console.log("clearRightsFor", isChecked, siteAccess.uid_site, page);
    }

    addAccess(siteObj: ClientSite) {
        if (this.selectedUser.site_access.has(siteObj.uid))
            this.myapp.showError("Site déja dans les droits d'accès de l'utilisateur");
        else {
            const fieldAccess = 'site_access.' + siteObj.uid;
            const updCommand = {};
            updCommand[fieldAccess] = {uid_site: siteObj.uid, following: true, responsability: "NONE", site_name: siteObj.name, rights: ['DASHBOARD@VIEW']};
            this.myapp.db.collection('users').doc(this.selectedUser.uid).update(updCommand).then(resp => {
                console.log("addAccess", resp, updCommand);
                this.myapp.db.collection('users').doc(this.selectedUser.uid).get().subscribe(u => {
                    const uObj = new User(u.data());
                    this.usersMap.set(uObj.uid, uObj);
                    this.filterUsers();
                    this.selectedUser = uObj;
                });
            });
        }
    }

    revoquer(uidSite: string) {
        this.confirmService.confirm({title: "Attention!", message: "L'utilisateur perdra accès au site immédiatement"})
            .subscribe(confirm => {
                if (confirm) {
                    this.updateUserAccess(uidSite, 'REVOQUE');
                }
            });
    }

    inviteUser() {
        if (this.selectedUser) {
            if (this.selectedUser.inviteToken)
                this.sendInvitationEmail();
            else {
                const data = {inviteToken: H.randomStr(10).toLowerCase()};
                this.myapp.db.collection('users')
                    .doc(this.selectedUser.uid).update(data).then(resp => {
                    console.log("updateUserInviteToken", resp);
                    this.sendInvitationEmail();
                    this.refreshUser(this.selectedUser.uid);
                });
            }
        }
    }

    sendInvitationEmail() {
        this.myapp.api.sendInviteEmail(this.selectedUser.inviteToken, this.selectedUser.email)
            .subscribe(resp => {
                console.log("sendInviteEmail", resp);
                this.myapp.showMessage("Mail d'invitation envoyé avec succès !");
            });
    }

    saveUser() {
        const {npa_loc, address, company, tel_fixed, tel_mobile, ...data} = this.formPdm.value;
        if (this.selectedUser.inviteToken) {
            // validate email unicity and first and last name
            const usersAll = Array.from(this.usersMap.values());
            const userWithSameEmail = usersAll.find(it =>
                it.email === data['email'] && it.uid !== this.selectedUser.uid);
            if (userWithSameEmail) {
                console.log("userWithSameEmail", data['uid'], userWithSameEmail, this.selectedUser);
                this.myapp.showError("Email existant");
                return;
            }
            if (data['first_name'] === 'NC' || data['last_name'] === 'NC') {
                this.myapp.showError("Modifier nom et prénom");
                return;
            }
        }
        //data['site_access'] = {};
        Object.keys(this.userRights).forEach(siteUID => {
            const rightsArr = [];
            Object.keys(this.userRights[siteUID]).forEach(accessTag => {
                if (this.userRights[siteUID][accessTag] === true) rightsArr.push(accessTag);
            });
            data['site_access.' + siteUID + '.rights'] = rightsArr.sort();
        });
        data.infos = {npa_loc, address, company, tel_fixed, tel_mobile};
        data.infos = H.removeNullsFromObject(data.infos);
        //data.site_rights = userRightsToArray;
        console.log('saveUser 22 SPLIT', H.removeNullsFromObject(data));
        console.log('saveUser', this.selectedUser, data);
        this.myapp.db.collection('users').doc(this.selectedUser.uid)
            .update(data).then(resp => {
            console.log("saveUser", resp);
            this.refreshUser(this.selectedUser.uid);
        });
    }

    refreshUser(uid) {
        this.myapp.db.collection('users').doc(uid).get().subscribe(u => {
            const uObj = new User(u.data());
            this.usersMap.set(uObj.uid, uObj);
            this.filterUsers();
            this.selectUser(uObj);
        });
    }

    updateGroup($event) {
        this.formPdm.controls['group'].patchValue($event);
    }

    updateRole($event) {
        this.formPdm.controls['role'].patchValue($event);
    }

    updateUserAccess(uid_site, newResponsability: any) {
        const field = 'site_access.' + uid_site;
        const updCommand = {};
        if (newResponsability === 'REVOQUE')
            updCommand[field] = deleteField();
        else {
            updCommand[field] = {
                uid_site, following: true,
                responsability: newResponsability,
                site_name: this.clientsSitesMap.get(uid_site).name
            };
        }
        console.log("updCommand", updCommand);
        this.myapp.db.collection('users').doc(this.selectedUser.uid).update(updCommand).then(resp => {
            console.log("Revoques", resp, updCommand);
            this.refreshUser(this.selectedUser.uid);
        });
    }
}

/*
    email: string;
    first_name: string;
    gender: string;
    last_name: string;
    office: string;
    role: string;
    register_token: string;

    ts_invited: number;
    uid: string;
    uid_bdd: string;

    invited: boolean;
    groups: string[];
    favs: string[];
    site_access: Map<string, SiteAccess> = new Map<string, SiteAccess>();
 */
